import { ChangeDetectionStrategy, Component, Output, EventEmitter, inject, ChangeDetectorRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProductService } from '@app/core/services/client/product.service';
import { LoadingService } from '@app/core/services/loading.service';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { Observable, Subscription } from 'rxjs';
import { LiquidateComponent } from '../liquidate/liquidate.component';

@Component({
  selector: 'app-assets',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    NgxSkeletonLoaderModule,
    LiquidateComponent
  ],
  templateUrl: './assets.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetsComponent {
  @Output() action = new EventEmitter<string>();
  @Input() product!: string;

  productService = inject(ProductService);
  loadingService = inject(LoadingService);
  change = inject(ChangeDetectorRef);
  store = inject(Store);


  returnPathUrl!: string;
  assets: any[] = [];
  subs: Subscription[] = [];
  isFetchingAssets$: Observable<boolean>;
  isLiquidateModalOpen: boolean = false;
  selectedAsset!: any;


  accountId = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);


  constructor () {
    this.isFetchingAssets$ = this.loadingService.getLoadingObservable('get-assets');
  }

  ngOnInit() {
    this.getAssets();
  }


  getAssets() {
    this.loadingService.setLoading('get-assets', true);
    const sub = this.productService.getAssets(this.accountId as string, this.product).subscribe({
      next: (res) => {

        this.assets = res.data;
        this.change.detectChanges();

        this.loadingService.setLoading('get-assets', false);
      },
      error: (err) => {
        this.loadingService.setLoading('get-assets', false);
        this.change.detectChanges();

      }
    });
    this.subs.push(sub);
  }


  toggleLiquidateModal(asset: any) {
    this.selectedAsset = asset;
  
    this.isLiquidateModalOpen = !this.isLiquidateModalOpen;
  }

  closeModal(): void {
    this.action.emit();
  }

  
  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
