<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>   
    <h1 class="text-mango-grey text-xl font-extrabold leading-6 text-center ">Liquidity and Cash Management Service</h1>
    <p class="text-mango-gray74 font-normal text-sm text-center mt-[5px]">Choose from these Assets</p>

    <div 
    class="flex flex-col text-left items-start space-y-5 p-[15px] rounded-[15px] shadow-sm mt-5 cursor-pointer hover:bg-gray-50"
    (click)="toggleFixedDeposit()"
    >
        <div class="flex items-center space-x-2.5">
            <div class="w-10 h-10 bg-mango-papayaWhip rounded-full flex items-center justify-center">
                <img
                  src="/assets/images/svgs/banknote-2.svg"
                  alt="fixed deposit icon"
                  class="w-6 h-6"
                />
              </div>
            <span class="text-sm font-extrabold text-mango-grey mt-[5px]">Fixed Deposit</span>
        </div>

        <p class="text-xs font-[350] text-mango-gray74">With guaranteed interest rates and a range of term options, fixed deposits provide a safe and reliable way to grow your savings with predictable, risk-free returns.</p>
    
    </div>

    <div 
    class="flex flex-col text-left items-start space-y-5 p-[15px] rounded-[15px] shadow-sm mt-5 cursor-pointer hover:bg-gray-50"
    (click)="toggleEuroBonds()"
    >
        <div class="flex items-center space-x-2.5">
            <div class="w-10 h-10 bg-mango-papayaWhip rounded-full flex items-center justify-center">
                <img
                  src="/assets/images/svgs/euro.svg"
                  alt="euro bonds icon"
                  class="w-6 h-6"
                />
              </div>
            <span class="text-sm font-extrabold text-mango-grey mt-[5px]">Bonds</span>
        </div>

        <p class="text-xs font-[350] text-mango-gray74">Offering guaranteed interest rates and various term options, bonds are a secure choice for growing your savings with steady, risk-free returns.</p>
    
    </div>

    <div 
    class="flex flex-col text-left items-start space-y-5 p-[15px] rounded-[15px] shadow-sm mt-5 cursor-pointer hover:bg-gray-50"
    (click)="toggleTreasuryBills()"
    >
        <div class="flex items-center space-x-2.5">
            <div class="w-10 h-10 bg-mango-papayaWhip rounded-full flex items-center justify-center">
                <img
                  src="/assets/images/svgs/money.svg"
                  alt="treasury bills icon"
                  class="w-6 h-6"
                />
              </div>
            <span class="text-sm font-extrabold text-mango-grey mt-[5px]">Treasury Bills</span>
        </div>

        <p class="text-xs font-[350] text-mango-gray74">Issued by the government, treasury bills provide safety, flexibility across different maturities, guaranteed returns, and high liquidity-making them a valuable addition to your investment portfolio.</p>
    
    </div>
    <div 
    class="flex flex-col text-left items-start space-y-5 p-[15px] rounded-[15px] shadow-sm mt-5 cursor-pointer hover:bg-gray-50"
    (click)="toggleCommercialPapers()"
    >
        <div class="flex items-center space-x-2.5">
            <div class="w-10 h-10 bg-mango-papayaWhip rounded-full flex items-center justify-center">
                <img
                  src="/assets/images/svgs/bill.svg"
                  alt="commercial papers icon"
                  class="w-6 h-6"
                />
              </div>
            <span class="text-sm font-extrabold text-mango-grey mt-[5px]">Commercial Papers</span>
        </div>

        <p class="text-xs font-[350] text-mango-gray74">Issued by corporations, commercial papers offer safety, flexibility with various maturities, competitive returns, and high liquidity. They serve as a reliable option for diversifying your investment portfolio.</p>
    
    </div>
     

</app-slide-in-right-modal>


<div *ngIf='isFixedDepositModalOpen'>
    <app-fixed-deposit (action)="toggleFixedDeposit()"></app-fixed-deposit>
  </div>

<div *ngIf='isEuroBondsModalOpen'>
    <app-bonds (action)="toggleEuroBonds()"></app-bonds>
  </div>

<div *ngIf='isTreasuryBillsModalOpen'>
    <app-treasury-bills (action)="toggleTreasuryBills()"></app-treasury-bills>
  </div>

<div *ngIf='isCommercialPapersModalOpen'>
    <app-commercial-papers (action)="toggleCommercialPapers()"></app-commercial-papers>
  </div>