import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withHashLocation } from '@angular/router';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { NgxsModule } from '@ngxs/store';
import { environment } from './core/environments/environment.production';
import { ErrorInterceptor } from './core/interceptors/http-errors.interceptor';
import { AuthState } from './auth/store/auth.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { provideToastr } from 'ngx-toastr';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withHashLocation()),
    provideHttpClient(withInterceptors([AuthInterceptor, ErrorInterceptor])),
    provideAnimations(),
    provideHotToastConfig(),
    provideToastr({
      maxOpened: 10,
      autoDismiss: false,
      iconClasses: {},
      positionClass: 'toast-top-right',
      closeButton: true,
      progressBar: true,
      tapToDismiss: true,
      enableHtml: true,
      newestOnTop: true,
      timeOut: 5000,
      preventDuplicates: true,
      // toastClass: '',
    }), // Toastr providers
    importProvidersFrom(
      NgxsModule.forRoot([AuthState], {
        developmentMode: !environment.production,
      }),
      NgxsReduxDevtoolsPluginModule.forRoot()
    ),
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
};
