import {  Component, OnInit, } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
// import { Toast, ToastrService, ToastPackage } from '../lib/public_api';
@Component({
  selector: 'app-custom-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './custom-toast.component.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
        transform: 'translateZ(85%)',
      })),
      transition('inactive => active', animate('300ms ease-in-out', keyframes([
        style({
          transform: 'translateZ(85%)',
          opacity: 0,
        }),
        style({
          transform:'translateZ(0%)',// 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('300ms ease-in-out', keyframes([
        style({
          // transform: 'translateY(0%)',
          opacity: 1,
        }),
        style({
          transform: 'translateZ(85%)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
  styles: `
    :host {
      padding: 15px 20px;
      margin: 10px;
      border-radius: 5px;
      color: #000;
      width: 300px;
      text-align: left;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.3),
      inset 0 0 10px rgba(255, 255, 255, 0.2);
      display: flex;
      align-items: center;
      pointer-events: all;
      cursor: pointer;
    }
    .btn-pink {
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0);
    }

    :host.error {
        background: linear-gradient(0deg, #f0e3e3de, #ffc1c1d6, #feb7b4de)
    }

    :host.warning {
        background: linear-gradient(0deg, #ffdd57de,#ffdd57d6, #ffcc00de);
    }

    :host.success {
        background: linear-gradient(0deg,#eefff4de,#e3ffe7d6,#d4ffe3de);
    }

    :host .icon {
        font-size: 24px;
        margin-right: 10px;
    }

    :host .content {
        flex: 1;
    }

    :host .title {
        font-weight: bold;
        margin-bottom: 5px;
    }
  `,
})

export class CustomToastComponent extends Toast {
  // undoString = 'undo';

  // constructor is only necessary when not using AoT
  constructor(
   protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }
  // change = inject(ChangeDetectorRef)

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

}
