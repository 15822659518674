import { Component, Output, EventEmitter, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from "../../../../shared/ui/slide-in-right-modal/slide-in-right-modal.component";
import { MutualFundComponent } from "../../mutual-fund.component";
import { ProductService } from '@app/core/services/client/product.service';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthActions } from '@app/auth/store/auth.actions';

@Component({
  selector: 'app-mutual-funds-agreement',
  standalone:true,

  imports: [CommonModule, SlideInRightModalComponent, MutualFundComponent],
  templateUrl: './mutual-funds-agreement.component.html',
  styleUrls:['./mutual-funds-agreement.components.css'],
  
})
export class MutualFundsAgreementComponent {
  @Output() action = new EventEmitter<string>();
  returnPathUrl:string = '/investment/plan'
  isMutualFundsModalOpen: boolean = false;
  productService = inject(ProductService);
  store = inject(Store);
  toast = inject(ToastrService);
  change = inject(ChangeDetectorRef);
  subs: Subscription[] = [];
  

  activeAccountId: any = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);
   toggleMutualFunds(): void {
    this.isMutualFundsModalOpen = !this.isMutualFundsModalOpen;
  }
  
 onAccept() {
    const sub = this.productService.setMFDAgreement(this.activeAccountId).subscribe({
      next: (res) => {
        this.toast.success('MFD Agreement accepted');
        this.store.dispatch(AuthActions.GetActiveAccountDetails)
        this.change.detectChanges();

      },
      error: () => {
        this.toast.error('Error accepting CSA');
      }
      
    });
    this.subs.push(sub);
  }


  onDecline(): void {
    this.action.emit();
  }
  

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

}

