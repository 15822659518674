import { ConfirmWithdrawComponent } from './pages/confirm-withdraw/confirm-withdraw.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { InputLabelComponent } from '@app/shared/ui/input-label/input-label.component';
import { InputComponent } from '@app/shared/ui/input/input.component';
import { BaseSelectDirective } from '@app/shared/directives/base-select.directive';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { Subscription } from 'rxjs';
import { LoadingService } from '@app/core/services/loading.service';
import { ProductService } from '@app/core/services/client/product.service';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { PortfolioService } from '@app/core/services/client/portfolio.service';

@Component({
  selector: 'app-withdraw',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    InputLabelComponent,
    InputComponent,
    BaseSelectDirective,
    ReactiveFormsModule,
    FormsModule,
    ButtonDirective,
    ConfirmWithdrawComponent
  ],
  templateUrl: './withdraw.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WithdrawComponent {
  @Output() action = new EventEmitter<string>();


  fb = inject(FormBuilder);
  change = inject(ChangeDetectorRef);
  loadingService = inject(LoadingService);
  productService = inject(ProductService);
  portfolioService = inject(PortfolioService);
  store = inject(Store);
  toast = inject(ToastrService);


  returnPathUrl!: string;
  availableBalance: number = 0;
  walletBalance: number = 0;
  portfolioCash: number = 0;
  isConfirmWithdrawalModalOpen: boolean = false;
  accountId: string = this.store.selectSnapshot(AuthSelectors.getActiveAccountID)!;


  subs: Subscription[] = [];


  withdrawalForm = this.fb.nonNullable.group({
    payment_source: ['', Validators.required],
    currency: ['', Validators.required],
    amount: ['', Validators.required],
  });

  ngOnInit() {
    this.getWalletBalance('NGN'); // Default to NGN on init
    this.getPortfolioCash('NGN'); // Default to NGN on init
    this.formChangesWatcher();
  }


  formChangesWatcher() {
    const paymentSourceSub = this.withdrawalForm
      .get('payment_source')
      ?.valueChanges.subscribe(() => this.updateAvailableBalance());

    const currencySub = this.withdrawalForm
      .get('currency')
      ?.valueChanges.subscribe((currency) => {
        this.getWalletBalance(currency); // Fetch wallet balance for selected currency
        this.getPortfolioCash(currency); // Fetch portfolio cash for selected currency
        this.updateAvailableBalance();
      });

    paymentSourceSub && this.subs.push(paymentSourceSub);
    currencySub && this.subs.push(currencySub);
  }
  reduceBalance(amount:any, availableBalance:number):number{
    const balance = availableBalance - amount;
    return balance;
  }

  updateAvailableBalance() {
    const paymentSource = this.withdrawalForm.get('payment_source')?.value;
    const amount:any = this.withdrawalForm.get('amount')?.value;

    if (paymentSource === 'cash_in_portfolio') {
      this.availableBalance = this.portfolioCash;
      this.availableBalance = this.availableBalance - amount;
    } else {
      this.availableBalance = this.walletBalance;
      this.availableBalance = this.availableBalance - amount;
    }

    this.change.detectChanges();
  }


  getWalletBalance(currency: string) {
    this.loadingService.setLoading('get-wallet-balance', true);
    const sub = this.productService.getWalletBalance(this.accountId).subscribe({
      next: (res: any) => {
        this.loadingService.setLoading('get-wallet-balance', false);

        // Use wallet_balance based on currency
        this.walletBalance =
          currency === 'USD' ? res.data[0].wallet_balance : res.data[1].wallet_balance;

        this.updateAvailableBalance(); // Update available balance
      },
      error: () => {
        this.loadingService.setLoading('get-wallet-balance', false);
        this.toast.error('Error getting wallet balance');
      },
    });
    this.subs.push(sub);
  }

  getPortfolioCash(currency: string) {
    this.loadingService.setLoading('get-portfolio-cash', true);
    const sub = this.portfolioService
      .getPortfolioCash(this.accountId, currency)
      .subscribe({
        next: (res: any) => {
          this.loadingService.setLoading('get-portfolio-cash', false);
          this.portfolioCash = res.data; // Update based on currency
          this.updateAvailableBalance(); // Update available balance
        },
        error: () => {
          this.loadingService.setLoading('get-portfolio-cash', false);
          this.toast.error('Error getting portfolio cash');
        },
      });
    this.subs.push(sub);
  }


  closeModal(): void {
    this.action.emit();
  }

  toggleConfirmWithdraw() {
    if (this.withdrawalForm.valid) {
      this.isConfirmWithdrawalModalOpen = !this.isConfirmWithdrawalModalOpen;
    }
  }

  handleWithdrawalOutcome(success: boolean) {
    if (success) {
      // On success, close all forms
      this.closeModal();
      this.isConfirmWithdrawalModalOpen = false;
    } else {
      // On failure, close all forms except the withdrawal form
      this.isConfirmWithdrawalModalOpen = false;
    }
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
