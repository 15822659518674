import { ChangeDetectionStrategy, Component, EventEmitter, Output, Input, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from '@app/shared/ui/input/input.component';
import { InputLabelComponent } from '@app/shared/ui/input-label/input-label.component';
import { AbstractControl, FormBuilder, FormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { BaseSelectDirective } from '@app/shared/directives/base-select.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { ProductService } from '@app/core/services/client/product.service';
import { Select, Store } from '@ngxs/store';
import { LoadingService } from '@app/core/services/loading.service';
import { ToastrService } from 'ngx-toastr';
import { PortfolioService } from '@app/core/services/client/portfolio.service';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { Observable, Subscription } from 'rxjs';
import { ICreateOrderResponse, IFixedDepositProduct, IPortfolioCashResponse, IProductRate, IProductRateResponse, IWalletBalanceResponse } from '@app/core/models/client/products/products.model';
import { AuthActions } from '@app/auth/store/auth.actions';
import { SpinDirective } from '@app/shared/directives/spin.directive';



interface InvestmentParameter extends IProductRate {
  label: string;
}
interface ProductOrderFormValues {
  product_id: string;
  portfolio_description: string;
  rate?: string;
}

@Component({
  selector: 'app-fixed-deposit-order',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    InputComponent,
    InputLabelComponent,
    BaseSelectDirective,
    ReactiveFormsModule,
    FormsModule,
    ButtonDirective,
    SpinDirective
  ],
  templateUrl: './fixed-deposit-order.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FixedDepositOrderComponent {
  @Output() action = new EventEmitter<string>();
  @Input() product!: IFixedDepositProduct;


  fb = inject(FormBuilder);
  productService = inject(ProductService);
  store = inject(Store);
  change = inject(ChangeDetectorRef);
  toast = inject(ToastrService);
  loadingService = inject(LoadingService);
  portfolioService = inject(PortfolioService);
  @Select(AuthSelectors.getLoadingStatus) loading$!: Observable<boolean>;


  returnPathUrl!: string;
  availableBalance: number = 0;
  walletBalance: number = 0;
  portfolioCash: number = 0;
  calculatedRate: number = 0;
  calculatedReturn: number = 0;
  selectedDuration: string = '';
  investmentParameters: InvestmentParameter[] = [];
  selectedInvestment: InvestmentParameter | null = null;
  currencySymbol: string = '';
  accountId: string = this.store.selectSnapshot(AuthSelectors.getActiveAccountID)!;

  subs: Subscription[] = [];


  productOrderForm = this.fb.nonNullable.group({
    payment_source: ['', Validators.required],
    amount: ['', Validators.required],
    comment: ['', Validators.required],
    product_id: [''],
    investment_parameter: [''],
    portfolio_description: ['']
  });

  ngOnInit() {
    this.getInvestmentParameters();
    this.getKycDetails();
    this.getPortfolioCash();
    this.formChangesWatcher();


    const formValues: ProductOrderFormValues = {
      product_id: this.product.id,
      portfolio_description: this.product.name,
    };



    // Patch the form with the filtered values
    this.productOrderForm.patchValue(formValues);

    // Add custom validator conditionally
    if (!this.product.category) {
      this.productOrderForm.get('amount')?.addValidators([
        Validators.required,
        this.amountValidator()
      ]);
      this.productOrderForm.get('amount')?.updateValueAndValidity();
    }
  }
  reduceBalance(amount:any, availableBalance:number):number{
    const balance = availableBalance - amount;
    return balance;
  }

  closeModal(): void {
    this.action.emit();
  }

  getKycDetails() {
    const sub = this.store.select(AuthSelectors.getActiveAccount).subscribe({
      next: (acc) => {
         this.walletBalance = acc?.wallet[1].wallet_balance ?? 0;
        this.change.detectChanges();
      },
      error: (err) => {
      },
    });
    this.subs.push(sub);
  }



  getInvestmentParameters() {
    this.loadingService.setLoading('get-investment-params', true);
    this.productService.getProductRate(this.product.id).subscribe({
      next: (res: IProductRateResponse) => {
        this.loadingService.setLoading('get-investment-params', false);

        this.investmentParameters = res.data.data.map((param: IProductRate) => {
          const currencySymbol = param.currency === 'NGN' ? '₦' : param.currency === 'USD' ? '$' : param.currency;

          return {
            id: param.id,
            label: `${currencySymbol}${param.min_amount.toLocaleString()} - ${currencySymbol}${param.max_amount.toLocaleString()} ${param.tenure} days, ${param.rate}%`,
            min_amount: param.min_amount,
            max_amount: param.max_amount,
            rate: param.rate,
            tenure: param.tenure,
            currency: param.currency
          };
        });
      },
      error: () => {
        this.loadingService.setLoading('get-investment-params', false);
        this.toast.error('Error fetching investment parameters');
      }
    });
  }



  getPortfolioCash() {
    this.loadingService.setLoading('get-portfolio-cash', true);
    const sub = this.portfolioService.getPortfolioCash(this.accountId, 'NGN').subscribe({
      next: (res: IPortfolioCashResponse) => {
        this.loadingService.setLoading('get-portfolio-cash', false);
        this.portfolioCash = res.data;
        this.change.detectChanges();

      },
      error: () => {
        this.loadingService.setLoading('get-portfolio-cash', false);
        this.toast.error('Error getting porfolio cash');
      },
    });
    this.subs.push(sub);
  }


  formChangesWatcher() {
    const paymentSourceSub = this.productOrderForm.get('payment_source')?.valueChanges.subscribe((val) => {
      if (val === 'cash_in_hand') {
        this.availableBalance = this.portfolioCash;
      } else {
        this.availableBalance = this.walletBalance;
      }
      this.change.detectChanges();
    });

    const amountSub = this.productOrderForm.get('amount')?.valueChanges.subscribe(() => {
      this.updateInvestmentDetails();
    });

    const investmentParamSub = this.productOrderForm.get('investment_parameter')?.valueChanges.subscribe(() => {
      this.updateInvestmentDetails();
    });

    // Push subscriptions to subs array for cleanup
    paymentSourceSub && this.subs.push(paymentSourceSub);
    amountSub && this.subs.push(amountSub);
    investmentParamSub && this.subs.push(investmentParamSub);
  }

  updateInvestmentDetails() {
    const selectedId: string | null = this.productOrderForm.get('investment_parameter')?.value ?? null;
    this.selectedInvestment = this.investmentParameters.find(param => param.id === (selectedId)) || null;

    if (this.selectedInvestment) {
      this.calculatedRate = Number(this.selectedInvestment.rate);
      this.selectedDuration = `${this.selectedInvestment.tenure} Days`;

      const amount = parseFloat(this.productOrderForm.get('amount')?.value || '0');
      this.calculatedReturn = amount > 0 ? amount + (amount * this.calculatedRate) / 100 : 0;
      this.currencySymbol = this.selectedInvestment.currency === 'NGN' ? '₦' :
        this.selectedInvestment.currency === 'USD' ? '$' :
          this.selectedInvestment.currency;

      // Update validator when investment parameter changes
      // this.productOrderForm.get('amount')?.updateValueAndValidity();
    }

    this.change.detectChanges();
  }


  // Custom validator function
  amountValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = parseFloat(control.value);

      if (!this.selectedInvestment) return null;

      const min_amount = this.selectedInvestment.min_amount;
      const max_amount = this.selectedInvestment.max_amount;

      if (isNaN(value) || value < min_amount || value > max_amount) {
        return {
          amountValidator: {
            min: min_amount,
            max: max_amount,
            actual: value
          }
        };
      }

      return null;
    };
  }

  createOrder() {
    this.store.dispatch(new AuthActions.SetLoadingStatus(true));
    // Extract form values
    const { payment_source, investment_parameter, ...rawPayload } = this.productOrderForm.getRawValue();

    // Convert amount to kobo (multiply by 100)
    if (rawPayload.amount) {
      rawPayload.amount = Math.round(parseFloat(rawPayload.amount) * 100).toString();
    }

    // Conditionally filter out rate and comment fields if payment_source is 'wallet'
    const payload = payment_source === 'wallet'
      ? (({ comment, portfolio_description, ...rest }) => rest)(rawPayload)
      : rawPayload;


    // Call the service to create the order
    const sub = this.productService.createOrder(this.accountId, payload, payment_source).subscribe({
      next: (res: ICreateOrderResponse) => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.toast.success('Order created successfully');
        this.store.dispatch(new AuthActions.SetWalletBalance(this.accountId));
        this.change.detectChanges();
        this.closeModal();
      },
      error: () => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.toast.error('Order creation failed');
        this.closeModal();
      },
    });

    this.subs.push(sub);
  }


  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}


interface WalletBalanceResponse {

}