import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { LoadingService } from '@app/core/services/loading.service';
import { Observable, Subscription } from 'rxjs';
import { ProductService } from '@app/core/services/client/product.service';
import { IFixedDepositProduct } from '@app/core/models/client/products/products.model';
import { PortfolioOrderComponent } from '../portfolio-order/portfolio-order.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FixedDepositOrderComponent } from '../fixed-deposit-order/fixed-deposit-order.component';

@Component({
  selector: 'app-fixed-deposit',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    PortfolioOrderComponent,
    FixedDepositOrderComponent,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './fixed-deposit.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixedDepositComponent {
  @Output() action = new EventEmitter<string>();

  productService = inject(ProductService);
  loadingService = inject(LoadingService);
  change = inject(ChangeDetectorRef);

  returnPathUrl!: string;
  subs: Subscription[] = [];
  products: IFixedDepositProduct[] = [];
  isProductOrderModalOpen: boolean = false;
  selectedFixedDeposit!: IFixedDepositProduct;
  isFetchingFixedDeposit$: Observable<boolean>;


  closeModal(): void {
    this.action.emit();
  }

  ngOnInit() {
    this.getFixedDepositProducts();
  }

  constructor() {
    this.isFetchingFixedDeposit$ = this.loadingService.getLoadingObservable('get-fixed-deposit-product');
  }

  getFixedDepositProducts() {
    this.loadingService.setLoading('get-fixed-deposit-product', true);
    const sub = this.productService.getFixedDepositProducts().subscribe({
      next: (res) => {
        this.products = res.data;
        this.change.detectChanges();

        this.loadingService.setLoading('get-fixed-deposit-product', false);
      },
      error: (err) => {
        this.loadingService.setLoading('get-fixed-deposit-product', false);
        this.change.detectChanges();

      }
    });
    this.subs.push(sub);
  }

  toggleOrder(product: IFixedDepositProduct) {
    this.selectedFixedDeposit = product;
    this.toggleProductOrder()
    
  }

  toggleProductOrder() {
    this.isProductOrderModalOpen = !this.isProductOrderModalOpen;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
