import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { OtpInputComponent } from '@app/shared/ui/otp-input/otp-input.component';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { WithdrawalService } from '@app/core/services/client/withdrawal.service';
import { ToastrService } from 'ngx-toastr';
import { WithdrawalFormData } from '@app/core/models/client/withdraw/withdraw.model';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { LoadingService } from '@app/core/services/loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-transaction-pin',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SlideInRightModalComponent,
    ButtonDirective,
    OtpInputComponent
  ],
  templateUrl: './transaction-pin.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionPinComponent {
  // @Output() action = new EventEmitter<string>();
  @Output() confirm = new EventEmitter<void>(); // Emit on successful withdrawal
  @Output() cancel = new EventEmitter<void>(); // Emit on cancellation
  @Input() withdrawalData!: WithdrawalFormData;

  withdrawalService = inject(WithdrawalService);
  toast = inject(ToastrService);
  store = inject(Store);
  loadingService = inject(LoadingService);


  pin = '';
  isLoading$!: Observable<boolean>;
  accountId = this.store.selectSnapshot(AuthSelectors.getActiveAccountID)!;

  constructor() {
    this.isLoading$ = this.loadingService.getLoadingObservable(
      'make-withdrawal-request'
    );
  }


  withdraw() {
    this.loadingService.setLoading('make-withdrawal-request', true);


    // Destructure to remove payment_source and create new payload
    const { payment_source, ...rest } = this.withdrawalData;
    const withdrawalPayload = {
      ...rest,
      account_id: this.accountId,
      transaction_pin: this.pin
    };

  

  

  

    this.withdrawalService.withdraw(withdrawalPayload).subscribe({
      next: (response) => {
        this.toast.success('Withdrawal successful');
        this.loadingService.setLoading('make-withdrawal-request', false);
        this.confirm.emit();

      },
      error: (error) => {
        this.loadingService.setLoading('make-withdrawal-request', false);
        this.cancel.emit();
        this.toast.error(error.error?.message || 'Withdrawal failed. Please try again.');
      }
    });
  }

  closeModal(): void {
    this.cancel.emit(); 
    
}
}