<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>
  <h1 class="text-center text-xl font-extrabold leading-6 text-mango-grey">
    Mutual Fund
  </h1>
  <p class="mt-[5px] text-center text-sm font-normal text-mango-gray74">
    Choose from these Assets
  </p>

  <div *ngIf="isMutualFundLoading$ | async" class="mt-[0.94rem]">
    <app-table-skeleton-loader></app-table-skeleton-loader>
  </div>
  @for (item of mutualFunds; track $index) {
    <div
      *ngIf="!(isMutualFundLoading$ | async)"
      class="mt-5 flex cursor-pointer flex-col items-start space-y-5 rounded-[15px] p-[15px] text-left shadow-sm hover:bg-gray-50"
      (click)="toggleMutualFund(item)"
    >
      <div class="flex items-center space-x-2.5">
        <div
          class="flex h-10 w-10 items-center justify-center rounded-full bg-mango-papayaWhip"
        >
          <img
            [src]="getImagePath($index)"
            [alt]="item?.name + ' icon'"
            class="h-6 w-6"
          />
        </div>
        <span class="mt-[5px] text-sm font-extrabold text-mango-grey">{{
          item?.name
        }}</span>
      </div>

      <p class="text-xs font-[350] text-mango-gray74">
        {{ item?.description }}
      </p>
    </div>
  }
</app-slide-in-right-modal>

@if (isProductOrderModalOpen) {
  <div>
    <app-mutual-fund-order
      (action)="toggleProductOrder()"
      [product]="selectedMutualFund"
    ></app-mutual-fund-order>
  </div>
}
