<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>
  <div class="flex flex-col items-center text-center">
    <!-- Product Type -->
    <span class="text-xl font-bold text-mango-grey">
      {{ asset?.AccountType ? 'Liquidity and Cash Management (LCM)' : 'Portfolio Management Service (PMS)' }}
    </span>

    <!-- Icon -->
    <img
      class="w-[163px] h-[127px] mb-3 mt-5"
      src="/assets/images/svgs/bank-note.svg"
      alt="Bank notes"
    />

    <!-- Balance -->
    <span class="text-sm font-medium text-mango-gray74">
      {{ asset?.valueLocal ? (asset.ccy === 'NGN' ? '₦' : asset.ccy === 'USD' ? '$' : '') : asset.currency }}
      {{ 
        asset?.valueLocal 
          ? ((asset.valueLocal / 100) | number:'1.2-2') 
          : ((asset.balance / 100) | number:'1.2-2') 
      }}
    </span>
  </div>
</app-slide-in-right-modal>
