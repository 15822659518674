<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>

<div class="flex flex-col items-center text-center">
    <span class="text-xl font-bold text-mango-grey">Commercial Papers</span>
    <img class="w-[163px] h-[127px] mb-3 mt-5" src="/assets/images/svgs/bank-note.svg" alt="Bank notes" />
    <span class="text-sm font-medium text-mango-gray74">Select the Bills you want to invest from the list below</span>
</div>


@if (!(isFetchingCommercialPaper$ | async)) {
  <div>
      @for (
        commercialPaper of commercialPapers;
        track commercialPaper.name;
        let i = $index
      ) {
      <div
      class="flex flex-col text-left items-start space-y-5 p-[15px] rounded-[15px] shadow-sm mt-5 cursor-pointer hover:bg-gray-50"
      (click)="toggleCommercialPaper(commercialPaper)"
    >
      <div class="flex items-center justify-center space-x-2.5">
        <div class="w-10 h-10 bg-mango-papayaWhip rounded-full flex items-center justify-center">
          <img
            src="/assets/images/svgs/bill.svg"
            alt="commercialPaper icon"
            class="w-6 h-6"
          />
        </div>
        
      
    
          <div class="flex flex-col space-y-1">
              <span class="text-sm font-extrabold text-mango-grey mt-[5px]">
                  {{ commercialPaper.name }}
              </span>
          <div class="flex items-center">
              <span class="text-mango-gray74 font-normal text-xs">Rate:</span>
              <span class="ml-1 font-extrabold text-mango-grey text-xs">{{ (commercialPaper.buy_rate) / 100 | number: '1.2-2' }}%</span>
              <span class="ml-3 bg-mango-papayaWhip text-xs font-semibold px-2 py-[2px] rounded-full text-mango-gray74">
                  {{ commercialPaper.currency | uppercase }}

              </span>
          </div>
      
          <div class="flex items-center ">
              <span class="text-mango-gray74 font-normal text-xs">Maturity Date</span>
              <span class="ml-1 font-extrabold text-mango-grey text-xs">{{ (commercialPaper.maturity_date) | date:
                  'MMM d, yyyy' }}</span>
          </div>
          </div>
      </div>
    </div>
    
      }
    </div>
} @else {
  <div class="flex flex-col mt-5">
    @for (_ of [0, 1, 2]; track $index) {
      <ngx-skeleton-loader
        [theme]="{ height: '5rem', width: '100%' }"
      ></ngx-skeleton-loader>
    }
  </div>
}


</app-slide-in-right-modal>

@if (isProductOrderModalOpen) {
  <div>
    <app-portfolio-order (action)="toggleProductOrder()" [product]="selectedCommercialPaper"></app-portfolio-order>
  </div>
}