import { ChangeDetectionStrategy, Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';

@Component({
  selector: 'app-liquidate',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,

  ],
  templateUrl: './liquidate.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiquidateComponent {
  @Output() action = new EventEmitter<string>();
  @Input() asset!: any;

  returnPathUrl!: string;
  product_type!: string;
  portfolio_balance!: string;

  closeModal(): void {
    this.action.emit();
  }
 

  
}
