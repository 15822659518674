import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { PortfolioDetailsComponent } from './pages/portfolio-details/portfolio-details.component';

@Component({
  selector: 'app-managed-portfolio',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    PortfolioDetailsComponent,
  ],
  templateUrl: './managed-portfolio.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagedPortfolioComponent {
  @Output() action = new EventEmitter<string>();


  returnPathUrl!: string;
  isConservativePortfolioModalOpen: boolean = false;
  isModeratePortfolioModalOpen: boolean = false;
  isAggressivePortfolioModalOpen: boolean = false;
  
  closeModal(): void {
    this.action.emit();
  }

  toggleconservativePortfolio(): void {
    this.isConservativePortfolioModalOpen = !this.isConservativePortfolioModalOpen;
  }

  toggleModeratePortfolio(): void {
    this.isModeratePortfolioModalOpen = !this.isModeratePortfolioModalOpen;

  }
  toggleAggressivePortfolio(): void {
    this.isAggressivePortfolioModalOpen = !this.isAggressivePortfolioModalOpen;
  }


}
