<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="onDecline()"
>
  <h2 class="mb-4 text-center text-lg font-bold text-mango-gray74">
    Mango Naira Money Market Fund Client Service Agreement (CSA)
  </h2>
  <div
    class="grid grid-cols-[20%_80%] items-center gap-4 rounded-lg bg-mango-floralWhite p-4"
  >
    <div class="font-bold text-mango">Asset Allocation</div>
    <div class="font-semibold text-mango-grey">
      Treasury Bills- 25%- 75%, Fixed deposit & Commercial Papers- 30% - 75%,
      Cash- 5%
    </div>
  </div>
  <div class="grid grid-cols-[20%_80%] items-center gap-4 bg-white p-4">
    <div class="font-bold text-mango">Fund Name</div>
    <div class="font-semibold text-mango-grey">
      Mango Naira Money Market Fund
    </div>
  </div>
  <div
    class="grid grid-cols-[20%_80%] items-center gap-4 rounded-lg bg-mango-floralWhite p-4"
  >
    <div class="font-bold text-mango">Handling Charges</div>
    <div class="font-semibold text-mango-grey">
      20% of accrued interest is charged when redemption is made before maturity
    </div>
  </div>
  <div class="grid grid-cols-[20%_80%] items-center gap-4 bg-white p-4">
    <div class="font-bold text-mango">Investment Horizon</div>
    <div class="font-semibold text-mango-grey">
      Capital Preservation and income generation
    </div>
  </div>
  <div
    class="grid grid-cols-[20%_80%] items-center gap-4 rounded-lg bg-mango-floralWhite p-4"
  >
    <div class="font-bold text-mango">Investment Objective</div>
    <div class="font-semibold text-mango-grey">
      Customized portfolio designed for capital appreciation and risk
      mitigation.
    </div>
  </div>
  <div class="grid grid-cols-[20%_80%] items-center gap-4 bg-white p-4">
    <div class="font-bold text-mango">Minimum Investment</div>
    <div class="font-semibold text-mango-grey">NGN 1,000</div>
  </div>
  <div
    class="grid grid-cols-[20%_80%] items-center gap-4 rounded-lg bg-mango-floralWhite p-4"
  >
    <div class="font-bold text-mango">Redemption</div>
    <div class="font-semibold text-mango-grey">Within 24 hours</div>
  </div>
  <div class="grid grid-cols-[20%_80%] items-center gap-4 bg-white p-4">
    <div class="font-bold text-mango">Risk</div>
    <div class="font-semibold text-mango-grey">
      Investors with Low to Medium risk appetite
    </div>
  </div>
  <div class="mt-6 flex justify-between space-x-2">
    <button
      (click)="onDecline()"
      class="rounded-lg border bg-red-600 px-8 py-3 font-bold text-white hover:bg-red-700 hover:text-white"
    >
      Decline
    </button>

    <button
      (click)="onAccept()"
      class="rounded-lg bg-mango px-8 py-3 font-bold text-white hover:brightness-90"
    >
      Accept
    </button>
  </div>
</app-slide-in-right-modal>

@if (isMutualFundsModalOpen) {
  <app-mutual-fund (action)="toggleMutualFunds()"></app-mutual-fund>
}
