<app-slide-in-right-modal [slideOpen]="true" [returnPath]="returnPathUrl" (action)="closeModal()">
    <h2 class="text-lg font-bold text-mango-gray74 text-center mb-4">
        PMS Client Service Agreement (CSA)
    </h2>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-mango-floralWhite rounded-lg">
        <div class="font-bold text-mango">Asset Allocation</div>
        <div class="text-mango-grey font-semibold">
            Diversified allocation across equities, fixed income, alternative
            investments, and real estate.
        </div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-white">
        <div class="font-bold text-mango">Fund Name</div>
        <div class="text-mango-grey font-semibold">Portfolio Management Service</div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-mango-floralWhite rounded-lg">
        <div class="font-bold text-mango">Handling Charges</div>
        <div class="text-mango-grey font-semibold">Nil Charges</div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-white">
        <div class="font-bold text-mango">Investment Horizon</div>
        <div class="text-mango-grey font-semibold">
            Capital Preservation and income generation
        </div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-mango-floralWhite rounded-lg">
        <div class="font-bold text-mango">Investment Objective</div>
        <div class="text-mango-grey font-semibold">
            Customized portfolio designed for capital appreciation and risk
            mitigation.
        </div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-white">
        <div class="font-bold text-mango">Minimum Investment</div>
        <div class="text-mango-grey font-semibold">NGN 100 Million</div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-mango-floralWhite rounded-lg">
        <div class="font-bold text-mango">Redemption</div>
        <div class="text-mango-grey font-semibold">Within 24 hours</div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-white">
        <div class="font-bold text-mango">Risk</div>
        <div class="text-mango-grey font-semibold">
            Investors with High risk appetite
        </div>
    </div>
    <div (click)="closeModal()" class="flex justify-between mt-6 space-x-2">
        <button class="px-8 py-3 border border-red-600 font-bold text-white rounded-lg hover:bg-red-700 bg-red-600 hover:text-white">
            Decline
        </button>
        <button (click)="acceptPMSAgreement()"
        class="px-8 py-3 bg-mango text-white font-bold rounded-lg hover:brightness-90">
             Accept
        </button>
    </div>
    
</app-slide-in-right-modal>

@if (isPortfolioManagementModalOpen) {
    <app-managed-portfolio (action)="togglePortfolioManagement()"></app-managed-portfolio>
 }