import { ChangeDetectionStrategy, Component, inject, Input, Output, EventEmitter, signal, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { ProfieDetails } from '@app/core/models/client/account/account-details.model';
import { CommonService } from '@app/core/services/common.service';

@Component({
  selector: 'app-account-details',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    ButtonDirective
  ],
  templateUrl: './account-details.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountDetailsComponent {
  @Output() action = new EventEmitter<string>();
  @Output() profileAction = new EventEmitter<string>();
  @Input() kycDetails?: ProfieDetails;
  store = inject(Store);
  returnPathUrl!: string;
  accountId!: string;
  isProfileOpen: boolean = false;
  activeAccount: any = this.store.selectSnapshot(AuthSelectors.getActiveAccount);

  private router = inject(Router);


  navigateTo(page: string): void {
    // const routeConfig = dashboardRoutesConfig[page];
    // const url = `${routeConfig.currentUrl[0]}/${this.accountId}`;
    // this.router.navigateByUrl(url); 
    this.router.navigateByUrl(`/${page}`);
  }
  toggleProfile(): void {
    this.profileAction.emit('profile');
  }

  closeModal(): void {
    this.action.emit();
  }
}
