import { ChangeDetectionStrategy, Component, Output, EventEmitter, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { ManagedPortfolioComponent } from '../../managed-portfolio.component';
import { ProductService } from '@app/core/services/client/product.service';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { AuthActions } from '@app/auth/store/auth.actions';

@Component({
  selector: 'app-pms-agreement',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    ManagedPortfolioComponent
  ],
  templateUrl: './pms-agreement.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PmsAgreementComponent {
  @Output() action = new EventEmitter<string>();

  productService = inject(ProductService);
  store = inject(Store);
  toast = inject(ToastrService);
  change = inject(ChangeDetectorRef);


  returnPathUrl!: string;
  isPortfolioManagementModalOpen: boolean = false;
  subs: Subscription[] = [];


  activeAccountId: any = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);


  togglePortfolioManagement(): void {
    this.isPortfolioManagementModalOpen = !this.isPortfolioManagementModalOpen;
  }

  acceptPMSAgreement() {
    const sub = this.productService.setPMSAgreement(this.activeAccountId).subscribe({
      next: (res) => {
        this.toast.success('PMS CSA accepted');
        this.change.detectChanges();
        this.store.dispatch(AuthActions.GetActiveAccountDetails)
      },
      error: () => {
        this.toast.error('Error accepting CSA');
      }
    });
    this.subs.push(sub);
  }

  closeModal(): void {
    this.action.emit();
  }
}
