import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { FixedDepositComponent } from './pages/fixed-deposit/fixed-deposit.component';
import { BondsComponent } from './pages/bonds/bonds.component';
import { TreasuryBillsComponent } from './pages/treasury-bills/treasury-bills.component';
import { CommercialPapersComponent } from './pages/commercial-papers/commercial-papers.component';

@Component({
  selector: 'app-lcm',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    FixedDepositComponent,
    BondsComponent,
    TreasuryBillsComponent,
    CommercialPapersComponent
  ],
  templateUrl: './lcm.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LCMComponent {
  @Output() action = new EventEmitter<string>();
  
  returnPathUrl!: string;
  isFixedDepositModalOpen: boolean = false;
  isEuroBondsModalOpen: boolean = false;
  isCommercialPapersModalOpen: boolean = false;
  isTreasuryBillsModalOpen: boolean = false;
  
  closeModal(): void {
    this.action.emit();
  }

  toggleFixedDeposit() {
    this.isFixedDepositModalOpen = !this.isFixedDepositModalOpen;
  }

  toggleEuroBonds() {
    this.isEuroBondsModalOpen = !this.isEuroBondsModalOpen;
  }

  toggleTreasuryBills() {
    this.isTreasuryBillsModalOpen = !this.isTreasuryBillsModalOpen;
  }

  toggleCommercialPapers() {
    this.isCommercialPapersModalOpen = !this.isCommercialPapersModalOpen;
  }
}
