import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from "../../shared/ui/slide-in-right-modal/slide-in-right-modal.component";
import { ProductService } from '@app/core/services/client/product.service';
import { Observable, Subscription } from 'rxjs';
import { Pagination } from '@app/core/models/client/account/pagination.models';
import { MutualFundOrderComponent } from "./pages/portfolio-order/mutual-fund-order.component";
import { TableSkeletonLoaderComponent } from "../../shared/ui/table-skeleton-loader/table-skeleton-loader.component";
import { LoadingService } from '@app/core/services/loading.service';

@Component({
  selector: 'app-mutual-fund',
  standalone: true,
  imports: [CommonModule, SlideInRightModalComponent, MutualFundOrderComponent, TableSkeletonLoaderComponent],
  templateUrl: './mutual-fund.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MutualFundComponent implements OnInit{

 @Output() action = new EventEmitter<string>();
 returnPathUrl!:string;
 productService = inject(ProductService);
 subs: Subscription[] = [];
 change = inject(ChangeDetectorRef);
 loadingService = inject(LoadingService);
 mutualFunds: any[]=[];
 pagination = {skip:0, sort:'asc'}
 isProductOrderModalOpen:boolean= false;
 selectedMutualFund:any;
 isMutualFundLoading$!: Observable<boolean>;
  closeModal(){
   this.action.emit();
  }
   imagePaths = [
     '/assets/images/svgs/banknote-2.svg', 
    '/assets/images/svgs/euro.svg', 
    '/assets/images/svgs/money.svg',
  ];

  constructor(){
     this.isMutualFundLoading$ = this.loadingService.getLoadingObservable('get-mutual-fund');
  }

  ngOnInit(): void {
    this.getMutualFunds();
  }

  getImagePath(index: number): string {
     return this.imagePaths[index]
  }
  toggleMutualFund(item:any){
   this.selectedMutualFund = item;
   this.toggleProductOrder();
  }


   getMutualFunds() {
    const sub = this.productService.getMutualFund(this.pagination).subscribe({
      next: (res) => {
        this.mutualFunds = res?.data;
        this.change.detectChanges();
      },
      error: () => {
      }
    });
    this.subs.push(sub);
  }
  toggleProductOrder(){
    this.isProductOrderModalOpen = !this.isProductOrderModalOpen;
  }
}


