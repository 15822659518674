import { ChangeDetectionStrategy, Component, Output, EventEmitter, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { ManagedPortfolioComponent } from '../managed-portfolio/managed-portfolio.component';


import { PmsAgreementComponent } from '../managed-portfolio/pages/pms-agreement/pms-agreement.component';
import { LcmAgreementComponent } from '../lcm/pages/lcm-agreement/lcm-agreement.component';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { LCMComponent } from '../lcm/lcm.component';
import { MutualFundsAgreementComponent } from '../mutual-fund/pages/mutual-funds-agreement/mutual-funds-agreement.component';
import { MutualFundComponent } from "../mutual-fund/mutual-fund.component";




@Component({
  selector: 'app-investment-plan',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    ManagedPortfolioComponent,
    LCMComponent,
    MutualFundsAgreementComponent,
    PmsAgreementComponent,
    LcmAgreementComponent,
    MutualFundComponent
],
  templateUrl: './investment-plan.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestmentPlanComponent {
  store = inject(Store);


  @Output() action = new EventEmitter<string>();


  isPortfolioManagementModalOpen: boolean = false;
  isLiquidityManagementModalOpen: boolean = false;
  isMutualFundsModalOpen: boolean = false;
  isLCMAgreement: boolean = false;
  isPMSAgreement: boolean = false;
  isMutualFundsAgreement: boolean = false;
  

  activeAccount$ = this.store.select(AuthSelectors.getActiveAccount);

  returnPathUrl!: string;
 
 

  ngOnInit() {
   
  }

  closeModal(): void {
    this.action.emit();
  }

  togglePortfolioManagement(): void {
    this.isPortfolioManagementModalOpen = !this.isPortfolioManagementModalOpen;
  }

  toggleLiquidityManagement(): void {
    this.isLiquidityManagementModalOpen = !this.isLiquidityManagementModalOpen;
  }
  
  toggleMutualFunds(): void {
    this.isMutualFundsModalOpen = !this.isMutualFundsModalOpen;
  }

  handleMutualFundsAgreement(event: any): void {
    this.isMutualFundsAgreement = true; // Mark the agreement as accepted
    this.isMutualFundsModalOpen = false;
  
}

closeMutualFundsModal(): void {
  this.isMutualFundsModalOpen = false; // Close the modal
}
}


 