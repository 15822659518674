<app-profile-slide-in-modal
  modalTitle="Account"
  [slideOpen]="true"
  (action)="closeModal()"
>
  <div class="page__container flex flex-col pb-8">
    <!-- Primary Account Card -->
    <div
      (click)="toggleAccountDetails()"
      class="mb-6 flex cursor-pointer items-center space-x-2 rounded-lg bg-white p-4 shadow-md hover:bg-gray-50"
    >
      <!-- Profile Icon -->
      @if (activeAccount) {
        <div
          class="flex h-10 w-10 items-center justify-center rounded-full bg-mango text-lg font-bold text-white"
        >
          @if (kycDetails?.passportPhotoUrl) {
            <img class="h-10 w-10 rounded-full bg-gray-50" src="{{ kycDetails?.passportPhotoUrl}}" alt="" />
          }
          {{ kycDetails?.passportPhotoUrl
            ? ''
            : activeAccount.user_profile.last_name.charAt(0) }}
        </div>
      }

      <!-- Account Details -->

      <div class="flex flex-col">
        @if (activeAccount?.account_type === 'corporate') {
          {{ activeAccount?.corporate_kyc?.name || '-' | uppercase }}
        }
        @if (activeAccount?.account_type === 'joint') {
          <!-- {{ getJointAccountName(activeAccount?.joint_kyc) || '-' }} -->
          ----
        }
        @if (activeAccount?.account_type === 'individual') {
          {{ activeAccount?.user_profile?.last_name | uppercase }}
          {{ activeAccount?.user_profile?.first_name }}
          {{ activeAccount?.user_profile?.middle_name || '' }}
        }
        <span class="text-xs font-semibold text-gray-700">
          ({{
            activeAccount?.account_type === 'individual'
              ? 'Individual'
              : 'Corporate'
          }})</span
        >
        <div class="flex items-center space-x-1">
          @if (activeAccount?.tier === 'one') {
            <img
              src="/assets/images/tier-one.png"
              alt="Tier One Logo"
              class="mr-1 h-4 w-11"
            />
          } @else if (activeAccount?.tier === 'two') {
            <img
              src="/assets/images/tier-two.png"
              alt="Tier Two Logo"
              class="mr-1 h-4 w-11"
            />
          } @else if (activeAccount?.tier === 'three') {
            <img
              src="/assets/images/tier-three.png"
              alt="Tier Two Logo"
              class="mr-1 h-4 w-11"
            />
          } @else {
            {{ '—' }}
          }

          <span class="text-xs text-mango-black17">{{
            activeAccount?.mango_account_number
          }}</span>
          @if (activeAccount?.is_blocked) {
            <span class="relative inline-flex items-center">
              <span
                class="rounded-full bg-red-200 px-2.5 py-1 text-xs leading-none text-red-500"
              >
                RESTRICTED
              </span>
            </span>
          }
        </div>
      </div>
    </div>
    @if (accountList.length > 1) {
      <!-- Switch Account Section -->
      <h2 class="pb-4 text-sm font-extrabold text-mango-grey">
        Switch Account
      </h2>
      <div class="space-y-4">
        <!-- Account Option -->

        @for (account of accountList; track $index) {
          <div
            (click)="switchAccount(account.id)"
            class="mb-6 flex cursor-pointer items-center space-x-2 rounded-lg bg-white p-4 shadow-md hover:bg-gray-50"
          >
            <!-- Account Details -->
            <div class="flex flex-1 flex-col">
              <span class="text-xs font-semibold text-gray-700">
                @if (account.account_type === 'corporate') {
                  {{ account.corporate_kyc?.name || '-' | uppercase }}
                }
                <!-- @if (account.account_type === 'joint') {
                {{ getJointAccountName(account.jo) || '-' }}
              } -->
                @if (account.account_type === 'individual') {
                  {{ account.user_profile.last_name | uppercase }}
                  {{ account.user_profile.first_name }}
                  {{ account.user_profile.middle_name || '' }}
                }
                ({{
                  account.account_type === 'individual'
                    ? 'Individual'
                    : 'Corporate'
                }})</span
              >
              <div class="flex items-center space-x-1">
                @if (account.tier === 'one') {
                  <img
                    src="/assets/images/tier-one.png"
                    alt="Tier One Logo"
                    class="mr-1 h-4 w-11"
                  />
                } @else if (account.tier === 'two') {
                  <img
                    src="/assets/images/tier-two.png"
                    alt="Tier Two Logo"
                    class="mr-1 h-4 w-11"
                  />
                } @else if (account.tier === 'three') {
                  <img
                    src="/assets/images/tier-three.png"
                    alt="Tier Two Logo"
                    class="mr-1 h-4 w-11"
                  />
                } @else {
                  {{ '—' }}
                }
                <span class="text-xs text-mango-black17">{{
                  account.mango_account_number
                }}</span>
                @if (account.is_blocked) {
                  <span class="relative inline-flex items-center">
                    <span
                      class="rounded-full bg-red-200 px-2.5 py-1 text-xs leading-none text-red-500"
                    >
                      RESTRICTED
                    </span>
                  </span>
                }
              </div>
            </div>
            @if (account.id === activeAccount?.id) {
              <div class="ml-auto">
                <img
                  src="/assets/images/svgs/check-mango.svg"
                  alt="Tier 1"
                  class="h-6 w-6"
                />
              </div>
            }
          </div>
        }
        <!-- Add Account -->
      </div>
    }
    <div class="space-y-4">
      <div
        (click)="toggleAddAccountModal()"
        class="mt-6 flex cursor-pointer items-center space-x-2 rounded-lg bg-white p-4 shadow-md hover:bg-gray-50"
      >
        <!-- Add Icon -->
        <div
          class="flex h-10 w-10 items-center justify-center rounded-full border border-mango-grayD9 bg-white text-2xl font-bold text-mango"
        >
          +
        </div>
        <a class="text-xs font-semibold text-gray-700">Add Account</a>
      </div>

      <!-- Logout -->
      <div
        (click)="logout()"
        class="mb-6 flex cursor-pointer items-center space-x-2 rounded-lg bg-white p-4 shadow-md hover:bg-gray-50"
      >
        <div
          class="flex h-10 w-10 items-center justify-center rounded-full border border-mango-grayD9 bg-white text-lg font-bold text-white"
        >
          <img
            class="h-5 w-5"
            src="/assets/images/svgs/arrow--logout.svg"
            alt="logout"
          />
        </div>
        <a class="text-xs font-semibold text-gray-700">Logout</a>
      </div>
    </div>
  </div>
</app-profile-slide-in-modal>

<!-- <div *ngIf="isAccountDetailsModalOpen">
    <app-account-details (action)="isAccountDetailsModalOpen = false;"></app-account-details>
</div> -->

<!-- <div *ngIf="isAddAccountModalOpen">
    <app-account-type (action)="isAddAccountModalOpen = false;"></app-account-type>
</div> -->
