import { ChangeDetectionStrategy, Component, inject, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { Subscription } from 'rxjs';
import { AccountService } from '@app/core/services/client/account.service';
import { ProfieDetails } from '@app/core/models/client/account/account-details.model';


@Component({
  selector: 'app-profile-details',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
  ],
  templateUrl: './profile-details.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileDetailsComponent{

  @Output() action = new EventEmitter<string>();
  @Input() kycDetails: ProfieDetails | undefined;

  store = inject(Store);
  accountService = inject(AccountService);

  subs: Subscription[] = [];
  accountDetails: any;
  returnPathUrl!: string;

  accountID = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);
  closeModal(): void {
    this.action.emit('profile');
  }



}
