import { Observable, finalize } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LoadingService } from '../loading.service';
import { environment } from '@app/core/environments/environment';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';


@Injectable({
  providedIn: 'root'
})
export class WithdrawalService {
  http = inject(HttpClient);
  loadingService = inject(LoadingService);
  store = inject(Store)


  activeAccountId: any = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);
  profileId: any = this.store.selectSnapshot(AuthSelectors.getProfileID);
  activeAccount: any = this.store.selectSnapshot(AuthSelectors.getActiveAccount);


  BASE_URL = environment.CLIENT_BASE_URL + '/v1';
  constructor() { }

  private convertToKobo(amount: number): number {
    return amount * 100;
  }


  withdraw(payload: any): Observable<any> {
    const uniqueStateUrl = 'make-withdrawal-request';
    this.loadingService.setLoading(uniqueStateUrl, true);

    if (payload.amount) {
      payload.amount = this.convertToKobo(payload.amount);
    }

    return this.http.post<any>(
      `${this.BASE_URL}/withdrawal/make-withdrawal-request`,
      payload).pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

}