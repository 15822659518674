import { ChangeDetectionStrategy, Component, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { AssetsComponent } from '../assets/assets.component';



@Component({
  selector: 'app-investment-type',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    AssetsComponent
  ],
  templateUrl: './investment-type.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestmentTypeComponent {
  @Output() action = new EventEmitter<string>();
  
  isAssetsModalOpen: boolean = false;
  selectedProduct!: 'PMS' | 'LCM';

  returnPathUrl!: string;

  togglePMSAssetsModal(): void {
    this.selectedProduct = 'PMS';
    this.toggleAssetsModal();
  }

  toggleLCMAssetsModal(): void {
    this.selectedProduct = 'LCM';
    this.toggleAssetsModal();

  }

  toggleAssetsModal(): void {
    this.isAssetsModalOpen = !this.isAssetsModalOpen;
  }

  closeModal(): void {
    this.action.emit();
  }
}


