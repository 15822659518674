import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { IBillsProduct, IWalletBalanceResponse } from '@app/core/models/client/products/products.model';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, Input, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { InputComponent } from '@app/shared/ui/input/input.component';
import { InputLabelComponent } from '@app/shared/ui/input-label/input-label.component';
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BaseSelectDirective } from '@app/shared/directives/base-select.directive';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { ProductService } from '@app/core/services/client/product.service';
import { Select, Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { LoadingService } from '@app/core/services/loading.service';
import { PortfolioService } from '@app/core/services/client/portfolio.service';
import { AuthActions } from '@app/auth/store/auth.actions';
import { SpinDirective } from '@app/shared/directives/spin.directive';


@Component({
  selector: 'app-mutual-fund-order',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    InputComponent,
    InputLabelComponent,
    ReactiveFormsModule,
    FormsModule,
    ButtonDirective,
    SpinDirective,
  ],
  templateUrl: './mutual-fund-order.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MutualFundOrderComponent {
  @Output() action = new EventEmitter<string>();
  @Input() product!: any;
  @Select(AuthSelectors.getLoadingStatus) loading$!: Observable<boolean>;



  fb = inject(FormBuilder);
  productService = inject(ProductService);
  store = inject(Store);
  change = inject(ChangeDetectorRef);
  toast = inject(ToastrService);
  loadingService = inject(LoadingService);
  portfolioService = inject(PortfolioService);


  returnPathUrl!: string;
  availableBalance: number = 0;
  walletBalance: number = 0;
  portfolioCash: number = 0;
  accountId: any = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);
  unitsCalculated: number = 0;
  amount: number = 0;
  subs: Subscription[] = [];
  unitPrice: number = 100;


  productOrderForm = this.fb.nonNullable.group({

    product_id: [this.product?.id],
    amount: ['', Validators.required],
    account_id: [this.accountId],

  });



  ngOnInit() {
    this.getKycDetails();
    const formValues: any = {
      product_id: this.product.id,

    };
    this.productOrderForm.patchValue(formValues);
    this.productOrderForm.get('amount')?.valueChanges.subscribe(() => {
      this.updateUnits();
    });


  }
  reduceBalance(amount:any, availableBalance:number):number{
    const balance = availableBalance - amount;
    return balance;
  }

  getKycDetails() {
    const sub = this.store.select(AuthSelectors.getActiveAccount).subscribe({
      next: (acc) => {
         this.availableBalance = acc?.wallet[1].wallet_balance ?? 0;
        this.change.detectChanges();
      },
      error: (err) => {
      },
    });
    this.subs.push(sub);
  }


  updateUnits() {
    const amount = Number(this.productOrderForm.get('amount')?.value) || 0;
    // const unit = this.productOrderForm.get('unit')?.value;
    // const unitPrice = 100;       
    this.unitsCalculated = Math.floor(amount / this.unitPrice);

  }

  closeModal(): void {
    this.action.emit();
  }





  createOrder() {
     this.store.dispatch(new AuthActions.SetLoadingStatus(true));
    if (this.productOrderForm.invalid) {
      this.toast.error('Please fill out the form correctly');
      return;
    }
    const { amount, ...rest } = this.productOrderForm.getRawValue();
    const payload = {
      amount: Number(amount) * 100, ...rest

    };
    const sub = this.productService.createMutualFundOrder(this.accountId, payload).subscribe({
      next: (res: any) => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.toast.success('Order created successfully');
        this.store.dispatch(AuthActions.GetActiveAccountDetails);
        this.closeModal();
        this.change.detectChanges();
      },
      error: () => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.toast.error('Order creation failed');
        this.closeModal();
      },
    });

    this.subs.push(sub);
  }




  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
