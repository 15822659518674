<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>
  <div class="flex flex-col items-center space-y-5 text-center">
    <span class="text-xl font-bold text-mango-black">{{ product.name }}</span>
    <img
      class="h-[127px] w-[163px]"
      src="/assets/images/svgs/bank-note.svg"
      alt="Bank notes"
    />
    <span class="text-lg font-semibold text-mango-black"
      >How much do you want to invest?</span
    >
    <span class="text-sm text-mango-gray74"
      >Consider market demand, investor preferences, and fund performance to
      decide the right amount</span
    >
  </div>

  <div class="mt-5 space-y-4 rounded-[15px] bg-mango-papayaWhip p-[15px]">
    <span>Product Information</span>
    <div class="flex justify-between">
      <span class="text-xs font-[350] text-mango-grey">Currency</span>
      <span class="text-sm font-extrabold tracking-[0.28px] text-mango-grey">
        {{ product?.currency | uppercase }}
      </span>
    </div>
    <div class="flex justify-between">
      <span class="text-xs font-[350] text-mango-grey">Unit Price:</span>
      <span class="text-sm font-extrabold tracking-[0.28px] text-mango-grey">
        {{ unitPrice | number: '1.0-0' }}
      </span>
    </div>
    <div class="flex justify-between">
      <span class="text-xs font-[350] text-mango-grey"
        >Units to be Purchased:</span
      >
      <span class="text-sm font-extrabold tracking-[0.28px] text-mango-grey">
        {{ unitsCalculated | number: '1.0-0' }}
      </span>
    </div>
  </div>

  <div class="page__container">
    <form
      class="form__container my-5 flex flex-col gap-5"
      [formGroup]="productOrderForm"
      (ngSubmit)="createOrder()"
    >
      <div>
        <app-input-label labelFor="amount" labelClass="label--bottom-margin"
          >Subscription Amount</app-input-label
        >
        <app-input
          placeholder="Enter Amount"
          inputClass="input--padding"
          formControlName="amount"
          [(ngModel)]="amount"
          (ngModelChange)="updateUnits()"
        ></app-input>

        <div class="mt-1 text-sm">
          Balance:
          {{ '₦'
          }}{{
            reduceBalance(
              productOrderForm.get('amount')?.value,
              availableBalance / 100
            ) | number: '1.0-2'
          }}
        </div>
      </div>

      <button
        mango-button
        [loading]="(loading$ | async) || productOrderForm.invalid"
        [disabled]="(loading$ | async) || productOrderForm.invalid"
        (click)="createOrder()"
        class="submit-button"
      >
        <span *ngIf="loading$ | async" spin></span>
        @if (!(loading$ | async)) {
          Purchase
        }
      </button>
    </form>
    <!-- <p>Form Valid: {{ productOrderForm.valid }}</p> -->
    <!-- <p>Amount Control Valid: {{ productOrderForm.get('amount')?.valid }}</p> -->
    <!-- <p>Unit Control Valid: {{ productOrderForm.get('unit')?.valid }}</p> -->
  </div>
</app-slide-in-right-modal>
