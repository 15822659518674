import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSlideInModalComponent } from '@app/shared/ui/profile-slide-in-modal/profile-slide-in-modal.component';
import { Router } from '@angular/router';
import { AuthActions } from '@app/auth/store/auth.actions';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { AuthService } from '@app/core/services/client/auth.service';
import { ProfieDetails } from '@app/core/models/client/account/account-details.model';
import { Subscription } from 'rxjs';
import { IAccountStateModel } from '@app/auth/store/auth.state.models';
import { environment } from '@app/core/environments/environment';


@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    CommonModule,
    ProfileSlideInModalComponent,
  ],
  templateUrl: './account.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class AccountComponent implements OnInit, OnDestroy {

  @Output() action = new EventEmitter<string>();
  // @Output() parentAction = new EventEmitter<>();
  @Input() kycDetails?: ProfieDetails;

  router = inject(Router);
  store = inject(Store);
  change = inject(ChangeDetectorRef);
  authService = inject(AuthService);


  returnPathUrl!: string;
  filePath = environment.FILE_BASE_URL;
  isAccountDetailsModalOpen: boolean = false;
  isAddAccountModalOpen: boolean = false;
  activeAccount: IAccountStateModel | null = this.store.selectSnapshot(AuthSelectors.getActiveAccount);
  activeAccount$ = this.store.select(AuthSelectors.getActiveAccount);
  accountList$ = this.store.select(AuthSelectors.getAllAccounts);
  accountList: IAccountStateModel[] = this.store.selectSnapshot(AuthSelectors.getAllAccounts);

  subs: Subscription[] = [];

  ngOnInit(): void {
    const sub = this.activeAccount$.subscribe((activeAccount) => {
      this.activeAccount = activeAccount;
      this.change.detectChanges();
    }
    );
    this.subs.push(sub);
    const sub2 = this.accountList$.subscribe((accountList) => {
      this.accountList = accountList;
      this.change.detectChanges();
    }
    );
    this.subs.push(sub2);
  }

  getJointAccountName(account: any[]) {
    return account.reduce((p, c, i) => {
      if (i > 0) {
        return (
          p +
          ` & ${c.personal_details.first_name} ${c.personal_details.last_name.toLocaleUpperCase()}`
        );
      }
      return `${c.personal_details.first_name} ${c.personal_details.last_name.toLocaleUpperCase()}`;
    }, '');
  }

  closeModal(): void {
    this.action.emit('account');
  }
  toggleAccountDetails(): void {
    this.action.emit('accountDetails');
  }

  toggleAddAccountModal(): void {
    this.action.emit();
    this.router.navigateByUrl('/dashboard/select-account-type')
  }

  switchAccount(account_id: string): void {
    if (account_id === this.activeAccount?.id) {
      // If the same account is clicked again, toggle details
      this.toggleAccountDetails();
    } else {
      // Otherwise, switch the account
      this.store.dispatch(new AuthActions.SwitchAccount(account_id));
    }
  }

  logout() {
    this.router.navigateByUrl('/login');
    this.authService.logout();
  }
  ngOnDestroy(): void {
    this.subs.forEach(sub => sub?.unsubscribe());
  }
}

