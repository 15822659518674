<div
  class="relative z-[80]"
  aria-labelledby="slide-over-title"
  role="dialog"
  data-open="true"
  aria-modal="true"
  tabindex="-1"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <!-- Background backdrop -->
  <div
    class="fixed inset-0 opacity-20"
    [ngClass]="{ 'bg-slate-600': isModalOpen }"
    (click)="closeModalAndNavigate()"
  ></div>

  <div class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden" (click)="closeModal()">
      <div
        class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 h-[70%]"
      >
        <div
          class="pointer-events-auto w-screen max-w-sm transform transition duration-500 ease-in-out sm:duration-700"
          [@slideOverAnimation]="isModalOpen ? 'open' : 'closed'"
        >
          <!-- Disable animations under this -->
          <div
            [@.disabled]="!slideOpen"
            @toggleModal
            class="flex h-full flex-col bg-white shadow-xl rounded-xl overflow-y-scroll"
            (click)="$event.stopPropagation()"
          >
            <!-- Title and Close Button Container -->
            <div class="px-6 pb-5 pt-[1.94rem]">
              <div class="flex items-center justify-between">
                <!-- Centered Title -->
                <h2
                  class="text-center font-bold text-mango-grey flex-grow text-lg"
                >
                  {{ modalTitle }}
                </h2>

                <!-- Close Button -->
                <button
                  (click)="closeModal()"
                  type="button"
                  class="ml-auto relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus-visible:outline-mango"
                >
                  <span class="absolute -inset-2.5"></span>
                  <span class="sr-only">Close panel</span>
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    aria-hidden="true"
                    stroke="currentColor"
                  >
                    <path
                      d="M18 6L6 18M6 6L18 18"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <!-- Modal Content -->
            <div class="flex-1 divide-gray-200 px-6 pb-8 pt-5">
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
