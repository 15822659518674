<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>
<!-- Profile Picture -->
<div class="flex flex-col items-center text-center">
    <span class="text-xl font-bold text-mango-grey">My Profile</span>
    <img class="w-16 h-16 rounded-full mb-3 mt-5" src="{{kycDetails?.passportPhotoUrl  ?? '/assets/images/avatar-image.png'}}" alt="User Photo" />
</div>


<!-- Personal Details -->
<div class="mt-8">
    <h3 class="text-base font-normal text-mango-grey">Personal Details</h3>
    <hr class="my-2 w-[80%]" />
    <div class="mt-4 space-y-2">
      <div class="flex justify-between">
        <span class="text-mango-gray74 font-normal text-sm">Full name</span>
        <span class="font-extrabold text-mango-grey text-base">  {{ kycDetails?.name | titlecase}} </span>
      </div>
      <div class="flex justify-between">
        <span class="text-mango-gray74 font-normal">Email</span>
        <span class="font-extrabold text-mango-grey">{{ kycDetails?.email || '-' }}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-mango-gray74 font-normal">Phone number</span>
        <span class="font-extrabold text-mango-grey">{{ kycDetails?.phone_number || '-' }}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-mango-gray74 font-normal">BVN</span>
        <span class="font-extrabold text-mango-grey">{{ kycDetails?.bvn }}</span>
      </div>
    </div>
  </div>


  <!-- Location -->
  <div class="mt-8">
    <h3 class="text-base font-normal text-mango-grey">Location</h3>
    <hr class="my-2 w-[80%]"/>
    <div class="mt-4 space-y-2">
      <div class="flex justify-between">
        <span class="text-mango-gray74">Country</span>
        <span class="font-extrabold text-mango-grey">{{ kycDetails?.country || '-'}}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-mango-gray74">State</span>
        <span class="font-extrabold text-mango-grey">{{ kycDetails?.state || '-'}}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-mango-gray74">City/Town</span>
        <span class="font-extrabold text-mango-grey">{{ kycDetails?.city || '-'}}</span>
      </div>
    </div>
</div>

</app-slide-in-right-modal>