<!-- <div class="toast success">
  <i class="icon fas fa-check-circle"></i>
  <div class="content">
    <div class="title">{{ title }}</div>
    {{ message }}
  </div>
</div> -->

<div class="row content" [style.display]="_state.value === 'inactive' ? 'none' : ''">
  <div class="col-9">
    <!-- @if (title) { -->
      <div [class]="options.titleClass" [attr.aria-label]="title">
        {{ title ? title : 'Hey!' }}
      </div>
    <!-- } -->

    @if (message && options.enableHtml) {
      <div
        role="alert"
        aria-live="polite"
        [class]="options.messageClass"
        [innerHTML]="message"
      ></div>
    }

    @if (message && !options.enableHtml) {
      <div
        role="alert"
        aria-live="polite"
        [class]="options.messageClass"
        [attr.aria-label]="message"
      >
        {{ message }}
      </div>
    }
  </div>
  <div class="col-3 text-right">
    @if (!options.closeButton) {
      <a class="btn btn-pink btn-sm" (click)="action($event)">
        {{ 'undo' }}
      </a>
    }
    @if (options.closeButton) {
      <a (click)="remove()" class="btn btn-pink btn-sm"> close </a>
    }
  </div>
</div>
@if (options.progressBar) {
  <div>
    <div class="toast-progress h-3" [style.width]="width + '%'"></div>
  </div>
}
