import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { AccountService } from '@app/core/services/client/account.service';
import { AccountComponent } from './modals/account/account.component';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { LoadingService } from '@app/core/services/loading.service';
import { AccountDetailsComponent } from './modals/account-details/account-details.component';
import { AccountTypeComponent } from './modals/create-account/account-type/account-type.component';
import { ProfileDetailsComponent } from './modals/profile-details/profile-details.component';
import { ProfieDetails } from '@app/core/models/client/account/account-details.model';
import { InvestmentPlanComponent } from '@app/investment/investment-plan/investment-plan.component';
import { InvestmentTypeComponent } from '@app/liquidate/investment-type/investment-type.component';
import { WithdrawComponent } from '@app/withdraw/withdraw.component';
import { environment } from '@app/core/environments/environment';
import { IDoc } from '@app/core/models/client/account/account-details.model';
import { IAccountStateModel } from '@app/auth/store/auth.state.models';
import { AuthActions } from '@app/auth/store/auth.actions';
import { PortfolioActions } from '@app/portfolio/store/portfolio.action';


@Component({
  selector: 'app-global-header',
  standalone: true,
  imports: [
    CommonModule,
    AccountComponent,
    AccountDetailsComponent,
    AccountTypeComponent,
    ProfileDetailsComponent,
    InvestmentPlanComponent,
    InvestmentTypeComponent,
    WithdrawComponent
  ],
  templateUrl: './global-header.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalHeaderComponent implements OnInit, OnDestroy {
  router = inject(Router);
  store = inject(Store);
  accountService = inject(AccountService);
  loadingService = inject(LoadingService);
  change = inject(ChangeDetectorRef);



  subs: Subscription[] = [];
  kycDetails?: ProfieDetails;
  openModal: string | null = null;
  isAccountModalOpen: boolean = false;
  isInvestmentPlanModalOpen: boolean = false;
  isInvestmentTypeModalOpen: boolean = false;
  isAccountDetailsModalOpen: boolean = false;
  isportfolioManagementModalOpen: boolean = false;
  isWithdrawModalOpen: boolean = false;
  tierLevelSubject$ = new BehaviorSubject<string>('');
  tierLevel$ = this.tierLevelSubject$.asObservable();
  isFetchingTierLevel$!: Observable<boolean>;


  profileID = this.store.selectSnapshot(AuthSelectors.getProfileID);
  accountID = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);
  activeAccount?: IAccountStateModel;
  accountList = this.store.selectSnapshot(AuthSelectors.getAllAccounts);
  activeAccount$ = this.store.select(AuthSelectors.getActiveAccount);

  constructor() {
    this.isFetchingTierLevel$ = this.loadingService.getLoadingObservable(
      'check-kyc-tier-level'
    );
  }
  ngOnInit(): void {
    // this.getProfileAccounts(this.profileID as string);
    // this.getAccountKycTier();
    this.store.dispatch(new AuthActions.EnumsList());
    const sub = this.activeAccount$.subscribe((account) => {
      if (account?.account_type === 'individual') {
        console.log('NEW Account data', account);
        this.activeAccount = account;
        let passportObj: any;
        if (Array.isArray(account.doc)) {
          passportObj = account.doc.filter((doc: IDoc) => doc.document_type === 'passport_photograph')[0];
        }
        this.kycDetails = {
          name: `${account.user_profile.first_name || ''} ${account.user_profile.last_name || ''}`.trim(),
          email: account.user_profile.email || '',
          phone_number: account.user_profile.phone_number_1 || '',
          bvn: account.user_profile?.bvn || '',
          country: account?.location_kyc?.country || '',
          state: account?.location_kyc?.state || '',
          city: account?.location_kyc?.city || '',
          passportPhotoUrl: `${passportObj?.link ? environment.FILE_BASE_URL + passportObj.link : ''}`
        };
        this.change.detectChanges();
      }

      this.store.dispatch(PortfolioActions.GetNGNStats)
      this.store.dispatch(PortfolioActions.GetUSDStats)
      this.change.detectChanges();
    });
    this.subs.push(sub);
  }

  toggleAccountModal(): void {
    this.isAccountModalOpen = !this.isAccountModalOpen;
  }

  // investing
  toggleInvestmentPlanModal(): void {
    this.isInvestmentPlanModalOpen = !this.isInvestmentPlanModalOpen;
  }

  // Liquidating
  toggleInvestmentTypeModal(): void {
    this.isInvestmentTypeModalOpen = !this.isInvestmentTypeModalOpen;
  }


  // Withdraw
  toggleWithdrawModal(): void {
    this.isWithdrawModalOpen = !this.isWithdrawModalOpen;
  }

  handleModalEvent(modalName: string): void {
    // Close all modals first
    this.isAccountModalOpen = false;
    this.isInvestmentPlanModalOpen = false;
    this.isInvestmentTypeModalOpen = false;
    this.isWithdrawModalOpen = false;
    this.openModal = null;

    // Open the requested modal
    if (this.openModal !== modalName) {
      this.openModal = modalName;
    }
  }


  // getKycDetails() {
  //   // Check the account type and call the appropriate endpoint
  //   if (this.activeAccount.account_type === 'individual') {
  //     const sub = this.accountService.getIndividualAccountKyc(this.accountID as string).subscribe({
  //       next: (res: any) => { 

  //       },
  //       error: (err) => {
  //         console.error('Error fetching individual KYC details:', err);
  //       },
  //     });
  //     this.subs.push(sub);
  //   } 
  //   else if (this.activeAccount.account_type === 'corporate') {
  //     const sub = this.accountService.getCoperateAccountKyc(this.accountID as string).subscribe({
  //       next: (res: any) => {

  //         this.kycDetails = {
  //           name: `${res.data.user.first_name || ''} ${res.data.user.last_name || ''}`.trim(),
  //           email: res.data.user.email || '',
  //           phone_number: res.data.user.phone_number_1 || '',
  //           bvn: res.data.user.bvn || '',
  //           country: res.data?.account_kyc.location_kyc?.country || '',
  //           state: res.data?.account_kyc.location_kyc?.state || '',
  //           city: res.data?.account_kyc.location_kyc?.city || '',

  //         };
  //         this.change.detectChanges();
  //       },
  //       error: (err) => {
  //         console.error('Error fetching corporate KYC details:', err);
  //       },
  //     });
  //     this.subs.push(sub);
  //   }
  // }

  navigate(page: string): void {
    this.router.navigate([`/${page}`]);
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
