import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { Observable, find } from 'rxjs';
import { Store } from '@ngxs/store';
import { inject } from '@angular/core';
import { AuthSelectors } from '@app/auth/store/auth.selectors';

export const AuthInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const store = inject(Store);
  // const authToken = store.selectSnapshot(AuthSelectors.getAuthToken);
  // const authToken = localStorage.getItem('accessToken');
  const authToken = sessionStorage.getItem('accessToken');
  const signUpToken = localStorage.getItem('signupToken');
  let headers = {};


  // Don't set Content-Type for FormData requests
  if (!(req.body instanceof FormData)) {
    headers = { 'Content-Type': 'application/json' };
  }


  /**
   * Any additional headers will make the API return 403
   */
  const token = authToken || signUpToken;
  if (token && req.url.indexOf('/auth/') === -1) {
    Object.assign(headers, {
      Authorization: `Bearer ${token}`,
    });
  }

  req = req.clone({
    setHeaders: headers,
    setParams: {},
  });

  return next(req);
};
