import { Routes } from '@angular/router';
import { LoginComponent } from './auth/pages/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';

export const routes: Routes = [
  { path: '', title: 'Login Page', component: LoginComponent },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.routes').then((route) => route.AUTH_ROUTES),
  },
  {
    path: 'create-account',
    loadChildren: () =>
      import('./create-account/create-account.routes').then(
        (route) => route.CREATE_ACCOUNT_ROUTES
      ),
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    loadChildren: () =>
      import('./dashboard/dashboard.routes').then(
        (route) => route.DASHBOARD_ROUTES
      ),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

export class AppRoutingModule {}
