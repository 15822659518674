import { ChangeDetectionStrategy, Component, Output, EventEmitter, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { ProductService } from '@app/core/services/client/product.service';
import { LoadingService } from '@app/core/services/loading.service';
import { Observable, Subscription } from 'rxjs';
import { IBondsProduct } from '@app/core/models/client/products/products.model';
import { PortfolioOrderComponent } from '../portfolio-order/portfolio-order.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-bonds',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    PortfolioOrderComponent,
    NgxSkeletonLoaderModule
  ],
  templateUrl: './bonds.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BondsComponent {
  @Output() action = new EventEmitter<string>();

  productService = inject(ProductService);
  loadingService = inject(LoadingService);
  change = inject(ChangeDetectorRef);

  returnPathUrl!: string;
  subs: Subscription[] = [];
  bonds: IBondsProduct[] = [];
  isProductOrderModalOpen: boolean = false;
  selectedBond!: IBondsProduct;
  isFetchingBonds$: Observable<boolean>;
  

  
  closeModal(): void {
    this.action.emit();
  }

  ngOnInit() {
    this.getBondsProducts();
  }

  constructor () {
    this.isFetchingBonds$ = this.loadingService.getLoadingObservable('get-bond-product');
  }

  getBondsProducts() {
    this.loadingService.setLoading('get-bond-product', true);
    const sub = this.productService.getBondsProducts().subscribe({
      next: (res) => {

        this.bonds = res.data.data;
        this.change.detectChanges();

        this.loadingService.setLoading('get-bond-product', false);
      },
      error: (err) => {
        this.loadingService.setLoading('get-bond-product', false);
        this.change.detectChanges();

      }
    });
    this.subs.push(sub);
  }

  toggleBond(bond: IBondsProduct) {
    this.selectedBond = bond;
    this.toggleProductOrder()
  }

  toggleProductOrder() {
    this.isProductOrderModalOpen = !this.isProductOrderModalOpen;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
