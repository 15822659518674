<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>
  <div class="flex flex-col items-center space-y-5 text-center">
    <span class="text-xl font-bold text-mango-black">{{ product.name }}</span>
    <img
      class="h-[127px] w-[163px]"
      src="/assets/images/svgs/bank-note.svg"
      alt="Bank notes"
    />
    <span class="text-lg font-semibold text-mango-black"
      >How much do you want to invest?</span
    >
    <span class="text-sm text-mango-gray74"
      >Consider market demand, investor preferences, and fund performance to
      decide the right amount</span
    >
  </div>

  @if (!product.category) {
    <div class="mt-5 space-y-4 rounded-[15px] bg-mango-papayaWhip p-[15px]">
      <span>Product Information</span>

      @if (product.buy_rate) {
        <div class="flex justify-between">
          <span class="text-xs font-[350] text-mango-grey">Rate:</span>
          <span
            class="text-sm font-extrabold tracking-[0.28px] text-mango-grey"
          >
            {{ product.buy_rate / 100 | number: '1.2-2' }}%
          </span>
        </div>
        <div class="flex justify-between">
          <span class="text-xs font-[350] text-mango-grey">Maturity Date:</span>
          <span
            class="text-sm font-extrabold tracking-[0.28px] text-mango-grey"
          >
            {{ product.maturity_date | date: 'MMM d, yyyy' }}
          </span>
        </div>
        <div class="flex justify-between">
          <span class="text-xs font-[350] text-mango-grey">Currency</span>
          <span
            class="text-sm font-extrabold tracking-[0.28px] text-mango-grey"
          >
            {{ product.currency | uppercase }}
          </span>
        </div>
      } @else {
        <div class="flex justify-between">
          <span class="text-xs font-[350] text-mango-grey">Price:</span>
          <span
            class="text-sm font-extrabold tracking-[0.28px] text-mango-grey"
          >
            {{
              (product.price < 0 ? -product.price : product.price) / 100
                | number: '1.0-2'
            }}
          </span>
        </div>
        <div class="flex justify-between">
          <span class="text-xs font-[350] text-mango-grey">Coupon Rate:</span>
          <span
            class="text-sm font-extrabold tracking-[0.28px] text-mango-grey"
          >
            {{
              (product.coupon_rate < 0
                ? -product.coupon_rate
                : product.coupon_rate) / 100 | number: '1.0-2'
            }}%
          </span>
        </div>
        <div class="flex justify-between">
          <span class="text-xs font-[350] text-mango-grey">Currency</span>
          <span
            class="text-sm font-extrabold tracking-[0.28px] text-mango-grey"
          >
            {{ product.currency | uppercase }}
          </span>
        </div>
      }
    </div>
  }

  <div class="page__container">
    <form
      class="form__container my-5 flex flex-col gap-5"
      [formGroup]="productOrderForm"
      (ngSubmit)="createOrder()"
    >
      <!-- Charge Source -->
      <div>
        <app-input-label
          labelFor="phone-number"
          labelClass="label--bottom-margin"
          >Charge Source</app-input-label
        >
        <select
          name="source"
          required
          base-select
          formControlName="payment_source"
        >
          <option value="" disabled selected>
            Select where to charge from
          </option>
          <option value="wallet">Wallet</option>
          <option value="cash_in_hand">Cash in Hand</option>
        </select>
      </div>

      <!-- Amount -->
      <div>
        <app-input-label labelFor="amount" labelClass="label--bottom-margin"
          >Amount</app-input-label
        >
        <app-input
          placeholder="Enter Amount"
          inputClass="input--padding"
          formControlName="amount"
        ></app-input>
        @if (productOrderForm.get('payment_source')?.value) {
          <div class="text-sm">
            Balance:
            {{ '₦'
            }}{{
              reduceBalance(
                productOrderForm.get('amount')?.value,
                availableBalance / 100
              ) | number: '1.0-2'
            }}
          </div>
        }
        <!-- Validation Error -->
        @if (productOrderForm.get('amount')?.hasError('minBondAmount')) {
          <div class="mt-1 text-sm text-red-500">
            Minimum investment amount is ₦{{
              product.min_bond_amount / 100 | number: '1.0-2'
            }}
          </div>
        }
      </div>

      <div>
        <app-input-label
          labelFor="date of birth"
          labelClass="label--bottom-margin"
          >Trade Date</app-input-label
        >
        <input
          placeholder="DD/MM/YYYY"
          class="block w-full rounded-lg border-0 pl-2 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango sm:leading-6"
          type="date"
        />
      </div>

      <!-- Comment -->
      <div>
        <app-input-label
          labelFor="bank-sort-code"
          labelClass="label--bottom-margin"
          >Comment</app-input-label
        >
        <app-input
          placeholder="Enter Comment"
          [inputClass]="'input--padding'"
          formControlName="comment"
        ></app-input>
      </div>
      <button
        mango-button
        [loading]="(loading$ | async) || productOrderForm.invalid"
        [disabled]="(loading$ | async) || productOrderForm.invalid"
        (click)="createOrder()"
        class="submit-button"
      >
        <span *ngIf="loading$ | async" spin></span>
        @if (!(loading$ | async)) {
          Confirm
        }
      </button>
    </form>
  </div>
</app-slide-in-right-modal>
