<div class="relative flex items-center justify-between bg-[#FBFBFB] p-5">
  <!-- Logo -->
  <div class="logo__container flex justify-start">
    <a href="#" class="cursor-pointer">
      <img
        class="mango-logo w-auto"
        src="/assets/images/svgs/mamvest-logo.svg"
        alt="Mamvest Logo"
      />
    </a>
  </div>

  <!-- Menu -->
  <div
    class="fixed bottom-0 left-0 right-0 z-50 flex h-[71px] transform justify-around gap-5 rounded-lg bg-white p-[10px] shadow-md lg:absolute lg:left-1/2 lg:-translate-x-1/2"
  >
    <!-- Home -->
    <div
      class="flex w-[55px] cursor-pointer flex-col items-center"
      (click)="navigate('dashboard')"
    >
      <img src="/assets/images/svgs/home.svg" />
      <span class="mt-2 text-xs font-extrabold text-mango-grey">Home</span>
    </div>
    <!-- Invest -->
    <div
      class="flex w-[55px] cursor-pointer flex-col items-center"
      (click)="toggleInvestmentPlanModal()"
    >
      <img src="/assets/images/svgs/price-up.svg" />
      <span class="mt-2 text-xs font-extrabold text-mango-grey">Invest</span>
    </div>
    <!-- Liquidate -->
    <div
      class="flex w-[55px] cursor-pointer flex-col items-center"
      (click)="toggleInvestmentTypeModal()"
    >
      <img src="/assets/images/svgs/profit.svg" />
      <span class="mt-2 text-xs font-extrabold text-mango-grey">Liquidate</span>
    </div>
    <!-- Withdraw -->
    <div
      class="flex w-[55px] cursor-pointer flex-col items-center"
      (click)="toggleWithdrawModal()"
    >
      <img src="/assets/images/svgs/atm-machine.svg" />
      <span class="mt-2 text-xs font-extrabold text-mango-grey">Withdraw</span>
    </div>
    <!-- Trades -->
    <div
      class="flex w-[55px] cursor-pointer flex-col items-center"
      (click)="navigate('trades')"
    >
      <img src="/assets/images/svgs/clock.svg" />
      <span class="mt-2 text-xs font-extrabold text-mango-grey">Trades</span>
    </div>
  </div>

  <!-- Profile Dropdown -->
  <div class="flex justify-end gap-x-4 lg:gap-x-6">
    <!-- Notifications -->
    <div class="mt-2 flex items-center justify-center">
      <img class="h-6 w-6" src="/assets/images/svgs/notification-bell.svg" />
    </div>

    <div class="relative">
      <!-- @if (activeAccount?.account_id) { -->
      <button
        type="button"
        class="-m-1.5 flex items-center p-1.5 focus-visible:outline-mango"
        id="user-menu-button"
        aria-expanded="false"
        aria-haspopup="true"
        (click)="toggleAccountModal()"
      >
        <div class="flex flex-col items-end text-right">
          <span class="font-semibold text-gray-700">
            @if (activeAccount?.account_type === 'corporate') {
              {{ activeAccount?.corporate_kyc?.name || '-' | uppercase }}
            }
            <!-- @if (account.account_type === 'joint') {
              {{ getJointAccountName(account.jo) || '-' }}
            } -->
            @if (activeAccount?.account_type === 'individual') {
              {{ activeAccount?.user_profile?.last_name | uppercase }}
              {{ activeAccount?.user_profile?.first_name }}
              {{ activeAccount?.user_profile?.middle_name || '' }}
            }
          </span>
          <div class="flex items-center space-x-2">
            <span
              *ngIf="activeAccount?.is_blocked"
              class="relative inline-flex items-center"
            >
              <span
                class="rounded-full bg-red-500 px-2.5 py-1 text-xs leading-none text-white"
              >
                RESTRICTED
              </span>
            </span>
            <span class="text-sm text-[#58595B]">{{
              activeAccount?.mango_account_number
            }}</span>
            <img
              *ngIf="activeAccount?.tier === 'one'"
              src="/assets/images/tier-one.png"
              alt="Tier One Logo"
              class="h-[15px] w-12"
            />
            <img
              *ngIf="activeAccount?.tier === 'two'"
              src="/assets/images/tier-two.png"
              alt="Tier Two Logo"
              class="h-[15px] w-12"
            />
            <img
              *ngIf="activeAccount?.tier === 'three'"
              src="/assets/images/tier-three.png"
              alt="Tier Three Logo"
              class="h-[15px] w-12"
            />
          </div>
        </div>
        <img
          class="ml-3 h-8 w-8 rounded-full bg-gray-50"
          [src]="
            kycDetails?.passportPhotoUrl || '/assets/images/avatar-image.png'
          "
          alt="Profile Picture"
        />
        <span class="hidden lg:flex lg:items-center">
          <svg
            class="ml-2 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>
    <!-- } -->
    </div>
  </div>
</div>

@if (isAccountModalOpen) {
  <app-account
    [kycDetails]="kycDetails"
    (action)="handleModalEvent($event)"
  ></app-account>
}

@if (openModal === 'accountDetails') {
  <app-account-details
    (action)="openModal = null"
    (profileAction)="openModal = 'profile'"
    [kycDetails]="kycDetails"
  ></app-account-details>
}

@if (openModal === 'addAccount') {
  <app-account-type (action)="openModal = null"></app-account-type>
}

@if (openModal === 'profile') {
  <app-profile-details
    (action)="openModal = null"
    [kycDetails]="kycDetails"
  ></app-profile-details>
}

@if (isInvestmentPlanModalOpen) {
  <app-investment-plan
    (action)="handleModalEvent($event)"
  ></app-investment-plan>
}

@if (isInvestmentTypeModalOpen) {
  <app-investment-type
    (action)="handleModalEvent($event)"
  ></app-investment-type>
}

@if (isWithdrawModalOpen) {
  <app-withdraw (action)="handleModalEvent($event)"></app-withdraw>
}
