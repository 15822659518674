import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { IBillsProduct } from '@app/core/models/client/products/products.model';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, Input, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { InputComponent } from '@app/shared/ui/input/input.component';
import { InputLabelComponent } from '@app/shared/ui/input-label/input-label.component';
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BaseSelectDirective } from '@app/shared/directives/base-select.directive';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { ProductService } from '@app/core/services/client/product.service';
import { Select, Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { LoadingService } from '@app/core/services/loading.service';
import { PortfolioService } from '@app/core/services/client/portfolio.service';
import { AuthActions } from '@app/auth/store/auth.actions';
import { SpinDirective } from '@app/shared/directives/spin.directive';

@Component({
  selector: 'app-portfolio-order',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    InputComponent,
    InputLabelComponent,
    BaseSelectDirective,
    ReactiveFormsModule,
    FormsModule,
    ButtonDirective,
    SpinDirective
  ],
  templateUrl: './portfolio-order.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioOrderComponent {
  @Output() action = new EventEmitter<string>();
  @Input() product!: any;




  fb = inject(FormBuilder);
  productService = inject(ProductService);
  store = inject(Store);
  change = inject(ChangeDetectorRef);
  toast = inject(ToastrService);
  loadingService = inject(LoadingService);
  portfolioService = inject(PortfolioService);


  returnPathUrl!: string;
  availableBalance: number = 0;
  walletBalance: number = 0;
  portfolioCash: number = 0;
  accountId: any = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);
  @Select(AuthSelectors.getLoadingStatus) loading$!: Observable<boolean>;

  subs: Subscription[] = [];


  productOrderForm = this.fb.nonNullable.group({
    payment_source: ['', Validators.required],
    amount: ['', Validators.required],
    rate: ['', Validators.required],
    comment: ['', Validators.required],
    product_id: [''],
    portfolio_description: ['']
  });

  ngOnInit() {
    this.getKycDetails();
    this.getPortfolioCash();
    this.formChangesWatcher();


    const formValues: any = {
      product_id: this.product.id,
      portfolio_description: this.product.name,
    };

    // Only include the `rate` field when the product is not FD
    if (this.product.buy_rate || this.product.coupon_rate) {
      formValues.rate = (this.product.buy_rate ? this.product.buy_rate : this.product.coupon_rate).toString();
    }

    // Patch the form with the filtered values
    this.productOrderForm.patchValue(formValues);
    // Add custom validator conditionally
    if (!this.product.category && !this.product.buy_rate) {
      this.productOrderForm.get('amount')?.addValidators([
        Validators.required,
        this.minBondAmountValidator(this.product.min_bond_amount)
      ]);
      this.productOrderForm.get('amount')?.updateValueAndValidity();
    }

  }
  reduceBalance(amount:any, availableBalance:number):number{
    const balance = availableBalance - amount;
    return balance;
  }

  closeModal(): void {
    this.action.emit();
  }

  getKycDetails() {
    const sub = this.store.select(AuthSelectors.getActiveAccount).subscribe({
      next: (acc) => {
         this.walletBalance = acc?.wallet[1].wallet_balance ?? 0;
        this.change.detectChanges();
      },
      error: (err) => {
      },
    });
    this.subs.push(sub);
  }



  getPortfolioCash() {
    this.loadingService.setLoading('get-portfolio-cash', true);
    const sub = this.portfolioService.getPortfolioCash(this.accountId, 'NGN').subscribe({
      next: (res: any) => {
        this.loadingService.setLoading('get-portfolio-cash', false);
        this.portfolioCash = res.data;
        this.change.detectChanges();

      },
      error: () => {
        this.loadingService.setLoading('get-portfolio-cash', false);
        this.toast.error('Error getting porfolio cash');
      },
    });
    this.subs.push(sub);
  }


  formChangesWatcher() {
    const sub = this.productOrderForm
      .get('payment_source')
      ?.valueChanges.subscribe((val) => {
        if (val === 'cash_in_hand') {
          // Set totalBalance to cash_in_balance
          this.availableBalance = this.portfolioCash;
          this.change.detectChanges();
        } else {
          this.availableBalance = this.walletBalance;
          this.change.detectChanges();
        }
      });

    sub && this.subs.push(sub);

  }

  // Custom validator function
  minBondAmountValidator(minAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = parseFloat(control.value);

      // Check if the value is a valid number and meets the minimum amount
      if (isNaN(value) || value < (minAmount / 100)) {
        return {
          minBondAmount: {
            required: minAmount / 100,
            actual: value
          }
        };
      }

      return null;
    };
  }

  createOrder() {
    this.store.dispatch(new AuthActions.SetLoadingStatus(true));
    // Extract form values
    const { payment_source, ...rawPayload } = this.productOrderForm.getRawValue();

    // Convert amount to kobo (multiply by 100)
    if (rawPayload.amount) {
      rawPayload.amount = Math.round(parseFloat(rawPayload.amount) * 100).toString();
    }

    // Conditionally filter out rate and comment fields if payment_source is 'wallet'
    const payload = payment_source === 'wallet'
      ? (({ rate, comment, portfolio_description, ...rest }) => rest)(rawPayload)
      : rawPayload;


    // Call the service to create the order
    const sub = this.productService.createOrder(this.accountId, payload, payment_source).subscribe({
      next: (res: any) => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.toast.success('Order created successfully');
        this.store.dispatch(AuthActions.GetActiveAccountDetails);
        this.closeModal();
        this.change.detectChanges();
      },
      error: () => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.toast.error('Order creation failed');
      },
    });

    this.subs.push(sub);
  }




  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
