import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { WithdrawalFormData } from '@app/core/models/client/withdraw/withdraw.model';
import { TransactionPinComponent } from '@app/withdraw/pages/transaction-pin/transaction-pin.component';

@Component({
  selector: 'app-confirm-withdraw',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    ButtonDirective,
    TransactionPinComponent
  ],
  templateUrl: './confirm-withdraw.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmWithdrawComponent {
  @Output() confirm = new EventEmitter<void>(); 
  @Output() cancel = new EventEmitter<void>(); 
  // @Output() action = new EventEmitter<string>();
  @Input() withdrawalData!: WithdrawalFormData;
  @Input() amount!: number;
  @Input() source!: string;
  @Input() currency!: string;


  isTransactionPinModalOpen: Boolean = false;

  closeModal(): void {
    this.cancel.emit();
  }

  toggleTransactionPinModal() {
    this.isTransactionPinModalOpen = !this.isTransactionPinModalOpen;
  }

  onTransactionPinSuccess() {
    this.confirm.emit(); 
    this.isTransactionPinModalOpen = false; 
  }

  onTransactionPinCancel() {
    this.cancel.emit(); 
    this.isTransactionPinModalOpen = false; 
  }
}
