<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>

<div class="flex flex-col items-center text-center">
    <span class="text-xl font-bold text-mango-grey">{{ product | uppercase }}</span>
    <img class="w-[163px] h-[127px] mb-3 mt-5" src="/assets/images/svgs/bank-note.svg" alt="Bank notes" />
    <span class="text-sm font-medium text-mango-gray74">Select an asset you want to liquidate</span>
</div>


@if (!(isFetchingAssets$ | async)) {
    <div>
        @for (
          asset of assets;
          track asset.name;
          let i = $index
        ) {
        <div
        (click)="toggleLiquidateModal(asset)"
        class="flex flex-col text-left items-start space-y-5 p-[15px] rounded-[15px] shadow-sm mt-5 cursor-pointer hover:bg-gray-50"
        
      >
        <div
        class="flex items-center justify-center space-x-2.5">
          <div class="w-10 h-10 bg-mango-papayaWhip rounded-full flex items-center justify-center">
            <span class="font-extrabold">{{  asset?.description ? asset.description[0]: asset.fundName[0] | uppercase }}</span>
          </div>
      
            <div class="flex flex-col space-y-1">
                <span class="text-sm font-extrabold text-mango-grey mt-[5px]">
                    {{ asset?.description ? asset.description: asset.fundName }}
                </span>
            <div class="flex items-center">
                <span class="text-mango-gray74 font-normal text-xs">Balance:</span>
                <span class="ml-1 font-extrabold text-mango-grey text-xs">
                    {{ (asset.currency || asset.ccy) === 'NGN' ? '₦' : (asset.currency || asset.ccy) === 'USD' ? '$' : '' }}
                    {{ (asset?.valueLocal ? asset.valueLocal : asset.balance / 100) | number: '1.2-2' }}</span>
                
            </div>
        
            <div class="flex items-center ">
                <span class="text-mango-gray74 font-normal text-xs">Status:</span>
                <span class="ml-3 bg-mango-papayaWhip text-xs font-semibold px-2 py-[2px] rounded-full text-mango-gray74">
                    Running
                </span>
            </div>
            </div>
        </div>
      </div>
      
        }
      </div>
    } @else {
      <div class="flex flex-col mt-5">
        @for (_ of [0, 1, 2]; track $index) {
          <ngx-skeleton-loader
            [theme]="{ height: '5rem', width: '100%' }"
          ></ngx-skeleton-loader>
        }
      </div>
    }
  
  </app-slide-in-right-modal>
  
  <div *ngIf='isLiquidateModalOpen'>
    <app-liquidate [asset]="selectedAsset" (action)="toggleLiquidateModal(selectedAsset)"></app-liquidate>
  </div> 
