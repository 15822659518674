<app-slide-in-right-modal [slideOpen]="true" (action)="closeModal()">


    <div class="p-[15px] space-y-4 rounded-2xl border border-[#FFF3E0]">

        <div class="flex justify-between">
            <span class="text-mango-grey text-xs font-[350]">Amount</span>
            <span class="font-extrabold text-mango-grey texProcessingt-sm tracking-[0.28px]">{{
                withdrawalData.currency === 'USD'
                ? '$'
                : '₦'
                }}{{ withdrawalData.amount | number: '1.0-2'}}</span>
        </div>

        
        <div class="flex justify-between">
            <span class="text-mango-grey text-xs font-[350]">Source</span>
            <span class="font-extrabold text-mango-grey text-sm tracking-[0.28px]">
                {{ 
                  withdrawalData.payment_source === 'cash_in_portfolio' 
                    ? 'From Cash In Portfolio' 
                    : withdrawalData.payment_source === 'wallet' 
                      ? 'From Wallet' 
                      : withdrawalData.payment_source | titlecase 
                }}
              </span>
        </div>
        <div class="flex justify-between">
            <span class="text-mango-grey text-xs font-[350]">Currency</span>
            <span class="font-extrabold text-mango-grey text-sm tracking-[0.28px]">{{ withdrawalData.currency }}</span>
        </div>
        
    </div>
    <button type="button" mango-button class="mt-5" (click)="toggleTransactionPinModal()">
    Confirm Withdrawal 
      </button>
   


</app-slide-in-right-modal>

<!-- @if (isTransactionPinModalOpen) {
    <div>
      <app-transaction-pin [withdrawalData]="withdrawalData" (action)="toggleTransactionPinModal()"> -->
        @if (isTransactionPinModalOpen) {
          <div>
            <app-transaction-pin
              [withdrawalData]="withdrawalData"
              (confirm)="onTransactionPinSuccess()"
              (cancel)="onTransactionPinCancel()"
            ></app-transaction-pin>

      
    </div>
  }
