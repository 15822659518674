<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>   
    <h1 class="text-mango-grey text-xl font-extrabold leading-6 text-center ">Investment Details</h1>
    <p class="text-mango-grey font-normal text-sm text-center mt-[5px]">Choose your preferred Investment Plan</p>

    
    <div 
    class="flex flex-col text-left items-start space-y-5 p-[15px] rounded-[15px] shadow-sm mt-5 cursor-pointer hover:bg-gray-50"
    (click)="togglePMSAssetsModal()"
    >
        <img src="/assets/images/svgs/pie-chart.svg" alt="pie chart" class="h-[45px] w-auto" />
        <div>
            <span class="text-sm font-extrabold text-mango-grey mt-[5px]">Portfolio Management Service</span>
            <p class="text-xs font-[350] text-mango-gray74">Provides personalized, professional management of your investment portfolio to help you reach your financial goals.</p>
            
        </div>
    </div>

    <div 
    class="flex flex-col text-left items-start space-y-5 p-[15px] rounded-[15px] shadow-sm mt-5 cursor-pointer hover:bg-gray-50"
    (click)="toggleLCMAssetsModal()"
   
    
    >
        <img src="/assets/images/svgs/office-bag.svg" alt="pie chart" class="h-[45px] w-auto" />
        <div>
            <span class="text-sm font-extrabold text-mango-grey">Liquidity and Cash Management Service</span>
            <p class="text-xs font-[350] text-mango-gray74 mt-[5px]">Offers a comprehensive solution for optimizing the management of your company's cash flow and liquidity needs.</p>
        </div>
    </div>

</app-slide-in-right-modal>





<div *ngIf='isAssetsModalOpen'>
    <app-assets [product]="selectedProduct" (action)="toggleAssetsModal()"></app-assets>
  </div> 

