<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>
  <div class="flex flex-col items-center text-center">
    <img
      class="mb-3 mt-5 h-[127px] w-[163px]"
      src="/assets/images/svgs/bank-note.svg"
      alt="Bank notes"
    />
    <span class="text-xl font-bold text-mango-grey"
      >How much do you want to Withdraw from your wallet?</span
    >
    <span class="text-sm font-medium text-mango-gray74"
      >We're here to help you meet your financial goals.</span
    >
  </div>

  <div class="page__container">
    <form
      class="form__container my-5 flex flex-col gap-5"
      [formGroup]="withdrawalForm"
    >
      <!-- Charge Source -->
      <div>
        <app-input-label
          labelFor="charge_source"
          labelClass="label--bottom-margin"
          >Select Source</app-input-label
        >
        <select
          name="source"
          required
          base-select
          formControlName="payment_source"
        >
          <option value="" disabled selected>
            Select where to charge from
          </option>
          <option value="wallet">Wallet</option>
          <option value="cash_in_portfolio">Cash In Portfolio</option>
        </select>
      </div>

      <!-- currency -->
      <div>
        <app-input-label labelFor="currency" labelClass="label--bottom-margin"
          >Select Currency</app-input-label
        >
        <select name="source" required base-select formControlName="currency">
          <option value="" disabled selected>Select Currency</option>
          <option value="NGN">NGN</option>
          <option value="USD">USD</option>
        </select>
      </div>

      <!-- Amount -->
      <div>
        <app-input-label labelFor="amount" labelClass="label--bottom-margin"
          >Amount</app-input-label
        >
        <app-input
          placeholder="Enter Amount"
          inputClass="input--padding"
          formControlName="amount"
        ></app-input>

        <!-- Current wallet balance -->
        @if (
          withdrawalForm.get('payment_source')?.value &&
          withdrawalForm.get('currency')?.value
        ) {
          <div class="mt-1 text-sm">
            Balance:
            {{ withdrawalForm.get('currency')?.value === 'NGN' ? '₦' : '$'
            }}{{
              reduceBalance(
                withdrawalForm.get('amount')?.value,
                availableBalance / 100
              ) | number: '1.0-2'
            }}
          </div>
        }
      </div>

      <button
        type="button"
        mango-button
        (click)="toggleConfirmWithdraw()"
        [disabled]="withdrawalForm.invalid"
      >
        Confirm
      </button>
    </form>
  </div>
</app-slide-in-right-modal>

<!-- @if (isConfirmWithdrawalModalOpen) {
<div>
  <app-confirm-withdraw [withdrawalData]="withdrawalForm.getRawValue()"
    (action)="toggleConfirmWithdraw()"> -->
@if (isConfirmWithdrawalModalOpen) {
  <div>
    <app-confirm-withdraw
      [withdrawalData]="withdrawalForm.getRawValue()"
      (confirm)="handleWithdrawalOutcome(true)"
      (cancel)="handleWithdrawalOutcome(false)"
    ></app-confirm-withdraw>
  </div>
}
