import { Observable, Subscription } from 'rxjs';
import { ChangeDetectionStrategy, Component, Output, EventEmitter, Input, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { AccountService } from '@app/core/services/client/account.service';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { IRelationshipManager } from '@app/core/models/client/account/account-rm.models';
import { LoadingService } from '@app/core/services/loading.service';

@Component({
  selector: 'app-portfolio-details',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,

  ],
  templateUrl: './portfolio-details.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioDetailsComponent {
  @Output() action = new EventEmitter<string>();
  @Input() portfolioType!: string;

  accountService = inject(AccountService);
  store = inject(Store);
  loadingService = inject(LoadingService);
  change = inject(ChangeDetectorRef);

  returnPathUrl!: string;
  subs: Subscription[] = [];
  accountId = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);
  manager: IRelationshipManager | undefined;
  isManagerLoading$: Observable<boolean>;


  constructor() {
    this.isManagerLoading$ = this.loadingService.getLoadingObservable('get-account-relationship-manager');
  }


  ngOnInit() {
    this.getAccountRelationshipManager();
  }


  closeModal(): void {
    this.action.emit();
  }

  getAccountRelationshipManager() {
    this.loadingService.setLoading('get-account-relationship-manager', true);
    const sub = this.accountService.getAccountRelationshipManager(this.accountId!).subscribe({
      next: (res) => {
        this.manager = res.data;
        this.change.detectChanges();

        this.loadingService.setLoading('get-account-relationship-manager', false);
      },
      error: (err) => {
        this.loadingService.setLoading('get-account-relationship-manager', false);
        this.change.detectChanges();

      }
    });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
