<div
  class="flex h-screen flex-col overflow-hidden pl-4 pr-4"
  style="
    background: url('/assets/images/bg-illustration.png') no-repeat;
    background-position: left bottom;
    background-size: 330px;
    background-color: #fbfbfb;
  "
>
  <app-global-header></app-global-header>
  <div class="flex-1 overflow-y-auto pb-16">
    <router-outlet></router-outlet>
  </div>
</div>
