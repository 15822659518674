<app-slide-in-right-modal [slideOpen]="true" [returnPath]="returnPathUrl" (action)="closeModal()">
    <h2 class="text-lg font-bold text-mango-gray74 text-center mb-4">
        LCM Client Service Agreement (CSA)
    </h2>

    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-mango-floralWhite rounded-lg">
        <div class="font-bold text-mango">Asset Class</div>
        <div class="text-mango-grey font-semibold">
            Fixed deposit, Treasury bills, Bonds, and Commercial papers
        </div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-white">
        <div class="font-bold text-mango">Fund Name</div>
        <div class="text-mango-grey font-semibold">Liquidity and Cash Management</div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-mango-floralWhite rounded-lg">
        <div class="font-bold text-mango">Tenor</div>
        <div class="text-mango-grey font-semibold">
            Short-term/bold-term/Long-term
        </div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-white">
        <div class="font-bold text-mango">Handling Charges</div>
        <div class="text-mango-grey font-semibold">
            25% of the accrued interest will be applied as a charge on Naira Fixed
            Deposits redeemed before maturity; 50% of the accrued interest will be
            applied as a charge on Dollar Fixed Deposits redeemed before maturity.
        </div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-mango-floralWhite rounded-lg">
        <div class="font-bold text-mango">Investment Horizon</div>
        <div class="text-mango-grey font-semibold">
            Capital Preservation and income generation
        </div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-white">
        <div class="font-bold text-mango">Investment Objectives</div>
        <div class="text-mango-grey font-semibold">Capital appreciation</div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-mango-floralWhite rounded-lg">
        <div class="font-bold text-mango">Minimum Investment</div>
        <div class="text-mango-grey font-semibold">₦1,000</div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-white">
        <div class="font-bold text-mango">Redemption</div>
        <div class="text-mango-grey font-semibold">Within 24 hours</div>
    </div>
    <div class="grid grid-cols-[20%_80%] items-center gap-4 p-4 bg-mango-floralWhite rounded-lg">
        <div class="font-bold text-mango">Risk</div>
        <div class="text-mango-grey font-semibold">
            Investors with low to medium risk Appetite
        </div>
    </div >
    <div (click)="closeModal()"
    class="flex justify-between mt-6 space-x-2">
        <button class="px-8 py-3 border border-red bg-red-600 text-white rounded-lg hover:bg-red-700 hover:text-white font-bold">
             Decline
        </button>
        <button (click)="acceptLCMAgreement()"
        class="px-8 py-3 bg-mango text-white rounded-lg hover:brightness-90 font-bold">
             Accept
        </button>
    </div>
</app-slide-in-right-modal>

@if (isLiquidityManagementModalOpen) {
    <app-lcm (action)="toggleLiquidityManagement()"></app-lcm>
}
