import { ChangeDetectionStrategy, Component, inject, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router} from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';
import { animate, state, style, transition, trigger } from '@angular/animations';


const enterTransition = transition(':enter', [
  style({
    transform: 'translateX(100%)',
  }),
  animate(
    '500ms ease-in-out',
    style({
      transform: 'translateX(0px)',
    })
  ),
]);

const slideOverAnimation = trigger('slideOverAnimation', [
  state(
    'open',
    style({
      transform: 'translateX(0)',
    })
  ),
  state(
    'closed',
    style({
      transform: 'translateX(100%)',
    })
  ),
  transition('open <=> closed', [animate('500ms ease-in-out')]),
]);

const toggleModal = trigger('toggleModal', [enterTransition]);

@Component({
  selector: 'app-profile-slide-in-modal',
  standalone: true,
  imports: [CommonModule, A11yModule],
  templateUrl: './profile-slide-in-modal.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toggleModal, slideOverAnimation],
})
export class ProfileSlideInModalComponent {
  isModalOpen: boolean = true;

  router = inject(Router);

  //flag to indicate if the modal should slide open on page load
  @Input() slideOpen: boolean = false;
  @Input() modalTitle!: string;
  @Input() showBackLink = true;
  @Output() action = new EventEmitter<void>();

  // @Input() returnPath!: string;

  // we check which page to return to after closing the modal
  modalReturnPage!: string;
  returnPath!: string;
  

  constructor() {
    // Get the current URL
    this.returnPath = this.router.url;
  }



  closeModal() {
    this.isModalOpen = false;
    this.action.emit()
  }

  closeModalAndNavigate() {
    console.log('closeModalAndNavigate called', this.returnPath);
    this.closeModal();
    if (this.returnPath) {
      this.router.navigate([this.returnPath]);
    } else {
      this.goBack();
    }
  }


  goBack() {
    history.back();
  }
}
