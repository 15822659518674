<app-slide-in-right-modal [slideOpen]="true" (action)="closeModal()">
    <div class="flex flex-col items-center gap-5">
        <div class="text-center">
            <h1 class="text-3xl text-mango-grey font-bold mb-2">Transaction Pin</h1>
            <p class="text-mango-grey text-lg">Please enter your transaction pin</p>
        </div>

        <div class="w-full max-w-md">
            <app-otp-input
                [(ngModel)]="pin"
                name="pinInput"
                #input="ngModel"
                inputType="password"
                required
            ></app-otp-input>
        </div>

        <div class="w-full mt-4">
            <button (click)="withdraw()"
                [class.opacity-50]="!input.valid || (isLoading$ | async)"
                [disabled]="(isLoading$ | async) || !input.valid"
                mango-button
            >
                @if ((isLoading$ | async)) {
                    Loading...
                } @else {
                    Confirm Withdrawal
                }
            </button>
        </div>


       
    </div>
</app-slide-in-right-modal>