import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError } from 'rxjs';
import { environment } from '../environments/environment';

export const ErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  let toast = inject(ToastrService);
  let router = inject(Router);

  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      if (err) {
        let erroMessage = err?.error?.message || err?.message || err?.statusText;
        if (erroMessage?.indexOf(environment.CLIENT_BASE_URL) > -1) {
          erroMessage = erroMessage?.replace(environment.CLIENT_BASE_URL, '');
        }
        switch (err.status) {
          case 0:
            toast.error('Please check your internet connection', "Connection Error");
            break;
          case 400:
            toast.error(erroMessage, "Bad Request");
            break;
          case 401:
            toast.error(erroMessage, "Unauthorized");
            break;
          case 409:
            toast.info('OTP has been sent to your email. Enter OTP to activate your account', "Conflict");
            break;
          case 403:
            toast.error('Please login', 'Authentication required');
            router.navigateByUrl('/auth/login');
            break;
          case 500:
            toast.error('Internal Server Error, please try again', "Error");
            break;
          case 502:
            toast.error('Please try again', "Gateway");
            break;
          default:
            toast.error(erroMessage);
            break;
        }
      }
      throw err;
    })
  );
};
