import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Observable, catchError } from 'rxjs';

export const ErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  let toast = inject(HotToastService);
  let router = inject(Router);

  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      if (err) {
        switch (err.status) {
          case 400:
            console.error('error 400', err);
            toast.error(err?.error?.message || err?.message || err?.statusText);
            break;
          case 401:
            console.error('error 401', err);
            toast.error(err?.error?.message || err?.message || err?.statusText);
            break;
          case 409:
            toast.error('OTP has been sent to your email. Enter OTP to activate your account');
            break;
          case 403:
            toast.error('Session expired, please login again');
            router.navigateByUrl('/auth/login');
            break;
          case 500:
            toast.error('Internal Server Error, please try again');
            break;
          case 502:
            toast.error('Internal Server Error, please try again');
            break;
          default:
            console.error('default: ', err);
            toast.error(err?.error?.message || err?.message || err?.statusText);
            break;
        }
      }
      throw err;
    })
  );
};
