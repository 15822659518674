<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>

<div class="flex flex-col items-center text-center">
    <span class="text-xl font-bold text-mango-grey">Fixed Deposit</span>
    <img class="w-[163px] h-[127px] mb-3 mt-5" src="/assets/images/svgs/bank-note.svg" alt="Bank notes" />
    <span class="text-sm font-medium text-mango-gray74">Select the product you want to invest from the list below</span>
</div>

@if (!(isFetchingFixedDeposit$ | async)) {
  <div>
      @for (
        product of products;
        track product.name;
        let i = $index
      ) {
        <div
          class="flex flex-col text-left items-start space-y-5 p-[15px] rounded-[15px] shadow-sm mt-5 cursor-pointer hover:bg-gray-50"
          (click)="toggleOrder(product)"
        >
          <div class="flex items-center space-x-2.5">
            <div class="w-10 h-10 bg-mango-papayaWhip rounded-full flex items-center justify-center">
              <img
                  src="/assets/images/svgs/banknote-2.svg"
                  alt="fixed deposit icon"
                  class="w-6 h-6"
              />
            </div>
            <span
              class="text-sm font-extrabold text-mango-grey mt-[5px]"
            >
              {{ product.name }}
            </span>
          </div>
          <p class="text-xs font-[350] text-mango-gray74">
            {{ product.description }}
          </p>
        </div>
      }
    </div>
  } @else {
    <div class="flex flex-col mt-5">
      @for (_ of [0, 1, 2]; track $index) {
        <ngx-skeleton-loader
          [theme]="{ height: '5rem', width: '100%' }"
        ></ngx-skeleton-loader>
      }
    </div>
  }



</app-slide-in-right-modal>

@if (isProductOrderModalOpen) {
  <div>
    <app-fixed-deposit-order (action)="toggleProductOrder()" [product]="selectedFixedDeposit"></app-fixed-deposit-order>
  </div>
}