<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>

  <!-- Profile Picture and Greeting -->
  <div class="flex flex-col items-center text-center">
    <img
      class="ml-3 h-16 w-16 rounded-full border border-solid bg-gray-50"
      [src]="kycDetails?.passportPhotoUrl || '/assets/images/avatar-image.png'"
      alt="Profile Picture"
    />
    <div class="flex items-center text-base text-gray-700">
      Hello
      <img
        src="/assets/images/svgs/hand-shake.svg"
        alt="Tier 1"
        class="h-5 w-auto"
      />
    </div>
    <p class="mt-1 text-lg font-semibold text-mango-grey">
      {{ kycDetails?.name | titlecase }}
    </p>
    <button
      (click)="toggleProfile()"
      type="button"
      mango-button
      class="mt-4 w-32"
    >
      View Profile
    </button>
  </div>

  <div class="mt-6 space-y-4">
    <!-- Compliance Check -->
    <div
      class="flex cursor-pointer items-center justify-between"
      (click)="navigateTo('compliance')"
    >
      <div class="flex items-center space-x-2">
        <span class="rounded-full bg-mango-floralWhite p-2 text-mango">
          <img src="/assets/images/svgs/clipboard-check.svg" />
        </span>
        <div>
          <p class="text-lg font-extrabold text-mango-grey">Compliance check</p>
          <p class="text-sm text-mango-gray74">
            Complete your profile registration
          </p>
        </div>
      </div>
      <span><img src="/assets/images/svgs/arrow--right.svg" /> </span>
    </div>

    <!-- Transactions -->
    <div
      class="flex cursor-pointer items-center justify-between"
      (click)="navigateTo('transactions')"
    >
      <div class="flex items-center space-x-2">
        <span class="rounded-full bg-mango-floralWhite p-2 text-mango">
          <img src="/assets/images/svgs/box.svg" />
        </span>
        <div>
          <p class="text-lg font-extrabold text-mango-grey">Transactions</p>
          <p class="text-sm text-mango-gray74">View transaction history</p>
        </div>
      </div>
      <span><img src="/assets/images/svgs/arrow--right.svg" /> </span>
    </div>

    <!-- Settings -->
    <div
      class="flex cursor-pointer items-center justify-between"
      (click)="navigateTo('settings/data-protection-policy')"
    >
      <div class="flex items-center space-x-2">
        <span class="rounded-full bg-mango-floralWhite p-2 text-mango">
          <img src="/assets/images/svgs/document--yellow.svg" />
        </span>
        <div>
          <p class="text-lg font-extrabold text-mango-grey">Data Protection Policy</p>
          <p class="text-sm text-mango-gray74">View Data Policy
            
          </p>
        </div>
      </div>
      <span><img src="/assets/images/svgs/arrow--right.svg" /> </span>
    </div>
    <div
      class="flex cursor-pointer items-center justify-between"
      (click)="navigateTo('settings/email-indemnity')"
    >
      <div class="flex items-center space-x-2">
        <span class="rounded-full bg-mango-floralWhite p-2 text-mango">
          <img src="/assets/images/svgs/document--yellow.svg" />
        </span>
        <div>
          <p class="text-lg font-extrabold text-mango-grey">Email Indemnity</p>
          <p class="text-sm text-mango-gray74">View Indemnity Terms
            
          </p>
        </div>
      </div>
      <span><img src="/assets/images/svgs/arrow--right.svg" /> </span>
    </div>
    <div
      class="flex cursor-pointer items-center justify-between"
      (click)="navigateTo('settings/terms-conditions')"
    >
      <div class="flex items-center space-x-2">
        <span class="rounded-full bg-mango-floralWhite p-2 text-mango">
          <img src="/assets/images/svgs/document--yellow.svg" />
        </span>
        <div>
          <p class="text-lg font-extrabold text-mango-grey">Terms and Conditions
          <p class="text-sm text-mango-gray74">View Terms and Conditions
            
          </p>
        </div>
      </div>
      <span><img src="/assets/images/svgs/arrow--right.svg" /> </span>
    </div>
    <div
      class="flex cursor-pointer items-center justify-between"
      (click)="navigateTo('settings/consent-statement')"
    >
      <div class="flex items-center space-x-2">
        <span class="rounded-full bg-mango-floralWhite p-2 text-mango">
          <img src="/assets/images/svgs/document--yellow.svg" />
        </span>
        <div>
          <p class="text-lg font-extrabold text-mango-grey">Consent Statement</p>
          <p class="text-sm text-mango-gray74"> View Consent Statement
            
          </p>
        </div>
      </div>
      <span><img src="/assets/images/svgs/arrow--right.svg" /> </span>
    </div>
    <div
      class="flex cursor-pointer items-center justify-between"
      (click)="navigateTo('settings/about-us')"
    >
      <div class="flex items-center space-x-2">
        <span class="rounded-full bg-mango-floralWhite p-2 text-mango">
          <img src="/assets/images/svgs/document--yellow.svg" />
        </span>
        <div>
          <p class="text-lg font-extrabold text-mango-grey">About Us</p>
          <p class="text-sm text-mango-gray74"> Who we are
            
          </p>
        </div>
      </div>
      <span><img src="/assets/images/svgs/arrow--right.svg" /> </span>
    </div>
    
  </div>

</app-slide-in-right-modal>
