import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withHashLocation } from '@angular/router';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { routes } from './app.routes';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';

import { NgxsModule } from '@ngxs/store';
import { environment } from './core/environments/environment.production';
import { ErrorInterceptor } from './core/interceptors/http-errors.interceptor';
import { AuthState } from './auth/store/auth.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { provideToastr } from 'ngx-toastr';
import { CustomToastComponent } from './shared/ui/custom-toast/custom-toast.component';
import { PortfolioState } from './portfolio/store/portfolio.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withHashLocation()),
    provideHttpClient(withInterceptors([AuthInterceptor, ErrorInterceptor])),
    provideAnimations(),
    provideToastr({
      maxOpened:2,
      autoDismiss: true,
      iconClasses: {
        error: 'error',
        info: 'info',
        success: 'success',
        warning: 'warning'
      },
      positionClass: 'toast-bottom-left',
      closeButton: true,
      progressBar: true,
      tapToDismiss: false,
      enableHtml: true,
      newestOnTop: true,
      timeOut: 8000,
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      toastClass: '',
      onActivateTick: true,
      progressAnimation: 'decreasing',
      toastComponent: CustomToastComponent
    }), // Toastr providers
    // provideHotToastConfig({
    //   reverseOrder: true,
    //   dismissible: true,
    //   autoClose: false,
    //   position: 'bottom-left',
    // }),
    importProvidersFrom(
      NgxsModule.forRoot([AuthState,PortfolioState], {
        developmentMode: !environment.production,
      }),
      NgxsReduxDevtoolsPluginModule.forRoot(),
    ),
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // importProvidersFrom(
    //   CustomToastComponent,
    //   BrowserAnimationsModule,
    //   BrowserModule
    // )
  ],
  
};
