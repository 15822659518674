import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { LCMComponent } from '../../lcm.component';
import { Subscription } from 'rxjs';
import { ProductService } from '@app/core/services/client/product.service';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { AuthActions } from '@app/auth/store/auth.actions';

@Component({
  selector: 'app-lcm-agreement',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    LCMComponent
  ],
  templateUrl: './lcm-agreement.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LcmAgreementComponent {
  @Output() action = new EventEmitter<string>();

  productService = inject(ProductService);
  store = inject(Store);
  toast = inject(ToastrService);
  change = inject(ChangeDetectorRef);

  subs: Subscription[] = [];
  returnPathUrl!: string;
  isLiquidityManagementModalOpen: boolean = false;

  activeAccountId: any = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);

  toggleLiquidityManagement(): void {
    this.isLiquidityManagementModalOpen = !this.isLiquidityManagementModalOpen;
  }


  acceptLCMAgreement() {
    const sub = this.productService.setLCMAgreement(this.activeAccountId).subscribe({
      next: (res) => {
        this.toast.success('LCM CSA accepted');
        this.change.detectChanges();
        this.store.dispatch(AuthActions.GetActiveAccountDetails)

      },
      error: () => {
        this.toast.error('Error accepting CSA');
      }
    });
    this.subs.push(sub);
  }

  // ngOnDestroy(): void {
  //   this.subs.forEach((sub) => sub.unsubscribe());
  // }




  closeModal(): void {
    this.action.emit();
  }
}
