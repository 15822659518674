<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>
<!-- Profile Picture -->
<div class="flex flex-col items-center text-center">
    <span class="text-xl font-bold text-mango-grey">{{ portfolioType | uppercase }}</span>
    <img class="w-[163px] h-[127px] mb-3 mt-5" src="/assets/images/svgs/bank-note.svg" alt="Bank notes" />
    <span class="text-sm font-medium text-mango-gray74">Please contact your Relationship Manager to proceed</span>
</div>


<!-- Personal Details -->
<div class="mt-8">
    <h3 class="text-base font-bold text-mango-gray74">Relationship Manager</h3>
    <hr class="my-2 w-[80%]" />
    <div class="mt-4 space-y-2">
      <div class="flex justify-between">
        <span class="text-mango-gray74 font-normal text-sm">Full name</span>
        <span class="font-extrabold text-mango-grey text-base">{{ (manager?.last_name || '') | titlecase }}  {{ (manager?.first_name || '') | titlecase }} </span>
      </div>
      <div class="flex justify-between">
        <span class="text-mango-gray74 font-normal text-sm">Email</span>
        <span class="font-extrabold text-mango-grey">{{ manager?.email || '-' }}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-mango-gray74 font-normal text-sm">Phone number</span>
        <span class="font-extrabold text-mango-grey">{{ manager?.phone_number || '-' }}</span>
      </div>
      
    </div>
  </div>



</app-slide-in-right-modal>