import { Routes } from '@angular/router';
import { LoginComponent } from './auth/pages/login/login.component';
import { AccountsLoadedGuard } from './auth/store/auth.guards';
import { LayoutComponent } from './shared/ui/layout/layout.component';

export const routes: Routes = [
  // { 
  //   path: '',  
  //   redirectTo: 'login',
  //   pathMatch: 'full' 
  // },
  {
    path: 'login',
    title: 'Login Page',
    component: LoginComponent
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.routes').then((route) => route.AUTH_ROUTES),
  },
  {
    path: 'create-account',
    loadChildren: () =>
      import('./create-account/create-account.routes').then(
        (route) => route.CREATE_ACCOUNT_ROUTES
      ),
  },
  {
    path: '',
    canActivate: [AccountsLoadedGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.routes').then(
            (route) => route.DASHBOARD_ROUTES
          ),
      },
      {
        path: 'compliance',
        loadChildren: () =>
          import('./compliance/compliance.routes').then(
            (route) => route.COMPLIANCE_ROUTES
          ),
      },
      {
        path: 'transactions',
        loadChildren: () =>
          import('./transactions/transactions.routes').then(
            (route) => route.TRANSACTIONS_ROUTES
          ),
      },
      {
        path: 'settings', 
        loadChildren: () =>
          import('./settings/settings.routes').then(
            (route) => route.SETTINGS_ROUTES
          ),
      },
     

      
      
      {
        path: 'orders',
        loadChildren: () =>
          import('./orders/orders.routes').then(
            (route) => route.ORDERS_ROUTES
          ),
      },
      {
        path: 'trades',
        loadChildren: () =>
          import('./trade/trade.routes').then(
            (route) => route.TRADES_ROUTES
          ),
      },
      {
        path: 'portfolio',
        loadChildren: () =>
          import('./portfolio/portfolio.routes').then(
            (route) => route.PORTFOLIO_ROUTES
          ),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];


export class AppRoutingModule { }
