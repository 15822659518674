import { Component, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from './core/services/client/auth.service';
import { interval, Subscription } from 'rxjs';
import { AccountService } from './core/services/client/account.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnDestroy{
  private authService = inject(AuthService);
  private userService = inject(AccountService);
  private router = inject(Router);
  private subs: Subscription[] = [];
  private inActiveTimer: number = 0;
  private logoutSub?: Subscription;
  constructor() {
    this.trackRefreshToken();
    this.trackLoginInactivity()
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.inActiveTimer = 0;
        this.logoutSub?.unsubscribe();
      } else {
        this.trackLoginInactivity();
      }
    });
  }


  trackLoginInactivity(): void {
    // Check if the user is on the auth
    if (this.router.url.indexOf('/auth/') > -1) {
      // Do nothing if the user is on the auth page
    }
    else {
      this.logoutSub = interval(60000) // 60 seconds
        .subscribe(() => {
          try{
            this.inActiveTimer += 60; //add 60 seconds
          //Logout after 5min of inactivity
          if (this.inActiveTimer >= (60 * 5) && this.router.url !== '/auth/login' && document.visibilityState === 'hidden') {
            this.authService.storeReturnUrl();
            this.router.navigateByUrl('/auth/login');
          } else {
            this.inActiveTimer = 0
          }
          } catch(e){
            console.log("Inactivity tracker error",e)
          }
        });
    }
  }
  trackRefreshToken(): void {
    const sub = interval(10000)
      .subscribe(() => {
        if (this.router.url.indexOf('/auth/') === -1 && document.visibilityState === 'visible') {
          const decoded = this.authService.getDecodedAccessToken();
          if (decoded?.exp) {
            const timer = decoded?.exp - (Date.now() / 1000);
            if (timer <= 45) {
              const sub = this.userService.getRefreshToken().subscribe(
                {
                  error: (error) => {
                    this.authService.storeReturnUrl();
                    this.router.navigateByUrl('/auth/login');
                  }
                }
              );
              this.subs.push(sub);
            }
          }
        }
      });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.logoutSub?.unsubscribe()
  }
}
