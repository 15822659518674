<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>
  <h1 class="text-center text-xl font-extrabold leading-6 text-mango-grey">
    Investment Plans
  </h1>
  <p class="mt-[5px] text-center text-sm font-normal text-mango-grey">
    Choose your preferred Investment Plan
  </p>

  <!-- Profile Picture and Greeting -->
  <div
    class="mt-5 flex cursor-pointer flex-col items-start space-y-5 rounded-[15px] p-[15px] text-left shadow-sm hover:bg-gray-50"
    (click)="toggleMutualFunds()"
  >
    <img
      src="/assets/images/svgs/office-bag.svg"
      alt="mutual funds"
      class="h-[45px] w-auto"
    />
    <div>
      <span class="text-sm font-extrabold text-mango-grey">Mutual Funds</span>
      <p class="text-xs font-[350] text-mango-gray74">
        Invest in a diversified portfolio of securities designed to meet your
        financial objectives with ease and flexibility.
      </p>
    </div>
  </div>

  <div
    class="mt-5 flex cursor-pointer flex-col items-start space-y-5 rounded-[15px] p-[15px] text-left shadow-sm hover:bg-gray-50"
    (click)="toggleLiquidityManagement()"
  >
    <img
      src="/assets/images/svgs/office-bag.svg"
      alt="pie chart"
      class="h-[45px] w-auto"
    />
    <div>
      <span class="text-sm font-extrabold text-mango-grey"
        >Liquidity and Cash Management Service</span
      >
      <p class="mt-[5px] text-xs font-[350] text-mango-gray74">
        Offers tailored solutions to optimize your company’s cash flow and
        liquidity needs through a range of instruments, including Fixed
        Deposits, Treasury Bills, and other Fixed Income securities.
      </p>
    </div>
  </div>
  <div
    class="mt-5 flex cursor-pointer flex-col items-start space-y-5 rounded-[15px] p-[15px] text-left shadow-sm hover:bg-gray-50"
    (click)="togglePortfolioManagement()"
  >
    <img
      src="/assets/images/svgs/pie-chart.svg"
      alt="pie chart"
      class="h-[45px] w-auto"
    />

    <div>
      <span class="mt-[5px] text-sm font-extrabold text-mango-grey"
        >Portfolio Management Service</span
      >
      <p class="text-xs font-[350] text-mango-gray74">
        Provides personalized, professional management of your investment
        portfolio to help you reach your financial goals.
      </p>
    </div>
  </div>
</app-slide-in-right-modal>

<div *ngIf="activeAccount$ | async as acc">
  @if (
    isPortfolioManagementModalOpen && !acc.accepted_pms_investment_agreement
  ) {
    <app-pms-agreement
      (action)="togglePortfolioManagement()"
    ></app-pms-agreement>
  }

  @if (
    isPortfolioManagementModalOpen && acc.accepted_pms_investment_agreement
  ) {
    <app-managed-portfolio
      (action)="togglePortfolioManagement()"
    ></app-managed-portfolio>
  }

  @if (
    isLiquidityManagementModalOpen && !acc.accepted_lcm_investment_agreement
  ) {
    <app-lcm-agreement
      (action)="toggleLiquidityManagement()"
    ></app-lcm-agreement>
  }

  @if (
    isLiquidityManagementModalOpen && acc.accepted_lcm_investment_agreement
  ) {
    <app-lcm (action)="toggleLiquidityManagement()"></app-lcm>
  }

  @if (isMutualFundsModalOpen && !acc.accepted_mutual_fund_agreement) {
    <app-mutual-funds-agreement
      (action)="toggleMutualFunds()"
    ></app-mutual-funds-agreement>
  }
  @if (isMutualFundsModalOpen && acc.accepted_mutual_fund_agreement) {
    <app-mutual-fund (action)="toggleMutualFunds()"></app-mutual-fund>
  }
</div>
