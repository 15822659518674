import { ChangeDetectionStrategy, Component, Output, EventEmitter, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { ProductService } from '@app/core/services/client/product.service';
import { LoadingService } from '@app/core/services/loading.service';
import { Observable, Subscription } from 'rxjs';
import { IBillsProduct } from '@app/core/models/client/products/products.model';
import { PortfolioOrderComponent } from '../portfolio-order/portfolio-order.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-treasury-bills',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    PortfolioOrderComponent,
    NgxSkeletonLoaderModule
  ],
  templateUrl: './treasury-bills.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreasuryBillsComponent {
  @Output() action = new EventEmitter<string>();

  productService = inject(ProductService);
  loadingService = inject(LoadingService);
  change = inject(ChangeDetectorRef);

  returnPathUrl!: string;
  subs: Subscription[] = [];
  treasuryBills: IBillsProduct[] = [];
  selectedTreasuryBill!: IBillsProduct; 
  isProductOrderModalOpen: boolean = false;
  isFetchingTBill$: Observable<boolean>;

  
  closeModal(): void {
    this.action.emit();
  }

  constructor() {
    this.isFetchingTBill$ = this.loadingService.getLoadingObservable('get-tbill-product');
  }

  ngOnInit() {
    this.getTBillProducts();
  }

  getTBillProducts() {
    this.loadingService.setLoading('get-tbill-product', true);
    const sub = this.productService.getTBillProducts().subscribe({
      next: (res) => {

        this.treasuryBills = res.data.data;
        this.change.detectChanges();

        this.loadingService.setLoading('get-tbill-product', false);
      },
      error: (err) => {
        this.loadingService.setLoading('get-tbill-product', false);
        this.change.detectChanges();

      }
    });
    this.subs.push(sub);
  }

  toggleTreasuryBill(tBill: IBillsProduct) {
    this.selectedTreasuryBill = tBill;
    this.toggleProductOrder()
  }

  toggleProductOrder() {
    this.isProductOrderModalOpen = !this.isProductOrderModalOpen;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
