<app-slide-in-right-modal
  [slideOpen]="true"
  [returnPath]="returnPathUrl"
  (action)="closeModal()"
>   
    <h1 class="text-mango-grey text-xl font-extrabold leading-6 text-center ">Managed Portfolio</h1>
    <p class="text-mango-grey font-normal text-sm text-center mt-[5px]">Choose a Portfolio</p>

    <!-- Profile Picture and Greeting -->
    <div class="flex flex-col text-left items-start space-y-2.5 p-[15px] rounded-[15px] shadow-sm mt-5 bg-[rgba(0,56,255,0.05)] h-[129px] cursor-pointer"
    (click)="toggleconservativePortfolio()">

    
        <img src="/assets/images/svgs/case.svg" alt="case" class="h-6 w-6 " />
        <div>
            <span class="text-sm font-extrabold text-mango-grey ">MODERATE PORTFOLIO</span>
            <p class="text-xs font-[350] text-mango-gray74 mt-[5px]">A conservative portfolio is geared towards investors prioritizing capital preservation and stability over high returns.</p>
        </div>
    </div>

    <div class="flex flex-col text-left items-start space-y-2.5 p-[15px] rounded-[15px] shadow-sm mt-5 bg-[rgba(244,160,28,0.05)] h-[129px] cursor-pointer" (click)="toggleModeratePortfolio()">
        <img src="/assets/images/svgs/case.svg" alt="case" class="h-6 w-6" />
        <div>
            <span class="text-sm font-extrabold text-mango-grey">CONSERVATIVE PORTFOLIO</span>
            <p class="text-xs font-[350] text-mango-gray74 mt-[5px]">A moderate portfolio strikes a balance between risk and return, catering to investors seeking a blend of growth potential and capital preservation.</p>
        </div>
    </div>

    <div class="flex flex-col text-left items-start space-y-2.5 p-[15px] rounded-[15px] shadow-sm mt-5 bg-[rgba(201,18,18,0.05)] h-[129px] cursor-pointer" (click)="toggleAggressivePortfolio()">
        <img src="/assets/images/svgs/case.svg" alt="case" class="h-6 w-6" />
        <div>
            <span class="text-sm font-extrabold text-mango-grey">AGGRESSIVE PORTFOLIO</span>
            <p class="text-xs font-[350] text-mango-gray74 mt-[5px]">An aggressive portfolio is designed for investors seeking high returns over the long term and are willing to accept a higher level of risk.</p>
        </div>
    </div>
    

 
  
     

</app-slide-in-right-modal>


<div *ngIf='isConservativePortfolioModalOpen'>
    <app-portfolio-details (action)="toggleconservativePortfolio()" [portfolioType]="'Conservative Portfolio'"></app-portfolio-details>
  </div>

  <div *ngIf='isAggressivePortfolioModalOpen'>
    <app-portfolio-details (action)="toggleAggressivePortfolio()" [portfolioType]="'aggressive Portfolio'"></app-portfolio-details>
  </div>

  <div *ngIf='isModeratePortfolioModalOpen'>
    <app-portfolio-details (action)="toggleModeratePortfolio()" [portfolioType]="'Moderate Portfolio'"></app-portfolio-details>
  </div>

 